import { PlansContext } from "../context/PlanContext";
import { useContext } from "react";

export const usePlansContext = () => {
    const context = useContext(PlansContext)

    if(!context){
        throw Error('usePlansContext must be used inside and PlansContextProvider')
    }

    return context
}