import { createContext, useReducer, useEffect } from "react";


export const authReducer = (state, action)=>{
    // console.log(action);

    switch(action.type){
        case 'LOGIN':
            return {user: action.payload}

        case 'LOGOUT':
            return {user:null}

        case 'UPDATE_USER':
            return {...state,user:action.payload}

            

        default:
            return state
    }
  
}

export const AuthContext = createContext()

export const AuthContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(authReducer,{
        user:null
    })


    useEffect(() => {

        const getUser = async () => {
            const response = await fetch('/api/superadmin/getUser')
            const json = await response.json();
            if(response.ok){
               
                const user = json.user;
    
                if (user) {
                  dispatch({ type: 'LOGIN', payload: user }) 
                }
            }else{
                  // remove user from storage
                  console.log('123');
    localStorage.removeItem('user')

    // dispatch logout action
    dispatch({ type: 'LOGOUT' })
    if(window.location.pathname != "/login")
{
    window.location.pathname = '/login';
}

            }
            
        }
        getUser()
        
      }, [])
      
    console.log('AuthContext state:' ,state)

    return(
        <AuthContext.Provider value={{...state, dispatch}}>
            {children}
        </AuthContext.Provider>
    )

}
