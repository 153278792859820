import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./App";
import { BrowserRouter } from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './store/store';
import reportWebVitals from "./reportWebVitals";
import  ThemeContext  from "./context/ThemeContext"; 
import {PlansContextProvider} from './context/PlanContext'
import {AuthContextProvider} from './context/AuthContext'
import {SubscribersContextProvider} from './context/SubscriberContext'
import './App.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<Provider store = {store}>            
			<BrowserRouter >
				<ThemeContext>
					<AuthContextProvider>
					<SubscribersContextProvider>
					<PlansContextProvider>
					<App />
					</PlansContextProvider>
					</SubscribersContextProvider>
					</AuthContextProvider>
				</ThemeContext>  
			</BrowserRouter>                
        </Provider>	
	</React.StrictMode>
);
reportWebVitals();
