import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import {
	Dropdown, Nav, Tab, Row, Col, Card, Table,
	Badge, Button, Modal
} from 'react-bootstrap';
import swal from "sweetalert";	
import VisitorActivity from "../../pages/WidgetBasic/VisitorActivity";
import ChartPie from "../charts/Chartjs/pie";

import { useAuthContext } from '../../../hooks/useAuthContext';


//Images
import chart1 from './../../../images/chart.png';
import pic3 from './../../../images/profile/small/pic3.jpg';
import pic4 from './../../../images/profile/small/pic4.jpg';
import pic5 from './../../../images/profile/small/pic5.jpg';
import pic6 from './../../../images/profile/small/pic6.jpg';
//import pic7 from './../../../images/profile/small/pic7.jpg';
import pic8 from './../../../images/profile/small/pic8.jpg';
import wind from './../../../images/big-wind.png';
import hunt from './../../../images/circle-hunt.png';

//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import ProjectStatisticsTab from './Dashboard/ProjectStatisticsTab';
import ProfileSlider from './Dashboard/ProfileSlider';

const CompletionApexChart = loadable(() =>
	pMinDelay(import("./Dashboard/CompletionApexChart"), 1000)
);
const ClientsColumnChart = loadable(() =>
	pMinDelay(import("./Dashboard/ClientsColumnChart"), 1000)
);
const NewCustomersApex = loadable(() =>
	pMinDelay(import("./Dashboard/NewCustomersApex"), 1000)
);
const NewCustomersApex2 = loadable(() =>
	pMinDelay(import("./Dashboard/NewCustomersApex2"), 1000)
);
const ProfileRedialApex = loadable(() =>
	pMinDelay(import("./Dashboard/ProfileRedialApex"), 1000)
);
const EmailChartApex = loadable(() =>
	pMinDelay(import("./Dashboard/EmailChartApex"), 1000)
);


const MessagesBlog = [
	{ images: pic8, title: 'Maren Rosser', para: 'Hei, dont forget to clear server cache!', datetime: '25min ago' },
	{ images: pic5, title: 'Kaiya Bergson', para: 'I remember that project due is tomorrow.', datetime: 'Yesterday, 8:24 AM' },
	{ images: pic6, title: 'Ruben Press', para: 'Ok sir. I will fix it as soon as possible', datetime: 'December 12th, 2020 10:24 AM' },
	{ images: pic3, title: 'Cristofer Torff', para: 'Maybe we should schedule that meeting', datetime: 'December 12th, 2020 10:24 AM' },
	{ images: pic4, title: 'Ann Rosser', para: 'I dont’t know where that files saved dude.', datetime: 'Yesterday, 8:24 AM' },
];

const Home = () => {
	const {user, dispatch} = useAuthContext()
	const { changeBackground } = useContext(ThemeContext);
	const [taskNumber, setTaskNumber] = useState(1);
	const [type, setType] = useState('task');
	const [label, setLabel] = useState('task');
	const [number, setNumber] = useState(1);
	const [taskModal, setTaskModal] = useState(false);
	const [id,setId] = useState(0)
    const [task_price,setTaskPrice] = useState(0)
    const [driver_price,setDriverPrice] = useState(0)
    const [admin_price,setAdminPrice] = useState(0)
    const [dispatcher_price,setDispatcherPrie] = useState(0)
    const [text_messaging_price,setTextMessagingPrice] = useState(0)
    const [price,setPrice] = useState(0)
	const svg1 = (
		<svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<rect x="0" y="0" width="24" height="24"></rect>
				<circle fill="#000000" cx="5" cy="12" r="2"></circle>
				<circle fill="#000000" cx="12" cy="12" r="2"></circle>
				<circle fill="#000000" cx="19" cy="12" r="2"></circle>
			</g>
		</svg>
	);

	useEffect(() => {
        const fetchFeature = async () => {
            const response = await fetch('/api/feature/')
            const json = await response.json();
            if(response.ok){
               
                setId(json._id)
                setTaskPrice(json.task_price)
                setTaskPrice(json.task_price)
                setDriverPrice(json.driver_price)
                setAdminPrice(json.admin_price)
                setDispatcherPrie(json.dispatcher_price)
                setTextMessagingPrice(json.text_messaging_price)
               
                // console.log(json);
            }
            
        }
        fetchFeature()
		changeBackground({ value: "light", label: "Light" });
    }, []);

	const openTaskModal = async(type) => {
        setTaskModal(true)
		if(type == 'task'){
			setPrice(task_price);
		}
		if(type == 'admin'){
			setPrice(admin_price);

		}
		if(type == 'dispatcher'){
			setPrice(dispatcher_price);
		}
		if(type == 'driver'){
			setPrice(driver_price);
		}
		setType(type);
		setLabel(type);
    }
	
	const handleSubmit = async() => {
		var email = user.email;
        const feature = {type,number,email}

        const response = await fetch('/api/feature/addFeature',{
            method:'POST',
            body:JSON.stringify(feature),
            headers:{
                'Content-Type':'application/json',
                // 'Authorization':`Bearer ${user.token}`
            }
        })

        const json = await response.json();
        if(!response.ok){
            // setError(json.error)
			setTaskModal(false)
            swal(json.error,'','error');
        }

        if(response.ok){
		


      // update the auth context
	//   localStorage.setItem('user', JSON.stringify(json))
    //   dispatch({type: 'UPDATE_USER', payload: json})
	  setTaskModal(false)
            swal("Feature Updated Successfully",'','success');
        }
    }

	return (
		<>
			<div className="row">
				<div className="col-xl-12">
					<div className="row">
					
						<div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
							<div className="widget-stat card bg-success">
								<div className="card-body p-4">
									<div className="media">
										<span className="me-3">
											<i className="flaticon-381-user-7"></i>
											
										</span>
										
										<div className="media-body text-white text-end">
											<p className="mb-1">Total Tasks 					
</p>
<button class="btn btn-primary btn-sm"   onClick={()=>openTaskModal('task')}> Add</button>

											<h3 className="text-white">{user?user.no_of_tasks:''}</h3>
											<div className="progress mb-2 bg-secondary">
                    <div
                      className="progress-bar progress-animated bg-white"
                      style={{ width: "80%" }}
                    ></div>
					
                  </div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
							<div className="widget-stat card bg-info">
								<div className="card-body p-4">
									<div className="media">
										<span className="me-3">
											<i className="flaticon-381-calendar-1"></i>
										</span>
										<div className="media-body text-white text-end">
											<p className="mb-1">Total Admin</p>
											<button class="btn btn-primary btn-sm" onClick={()=>openTaskModal('admin')}> Add</button>

											<h3 className="text-white">{user?user.no_of_admins:''}</h3>
											<div className="progress mb-2 bg-secondary">
                    <div
                      className="progress-bar progress-animated bg-white"
                      style={{ width: "60%" }}
                    ></div>
                  </div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
							<div className="widget-stat card bg-warning">
								<div className="card-body p-4">
									<div className="media">
										<span className="me-3">
											<i className="flaticon-381-calendar-1"></i>
										</span>
										<div className="media-body text-white text-end">
											<p className="mb-1">Total Dispatchers</p>
											<button class="btn btn-primary btn-sm" onClick={()=>openTaskModal('dispatcher')}> Add</button>

											<h3 className="text-white">{user?user.no_of_dispatchers:''}</h3>
											<div className="progress mb-2 bg-secondary">
                    <div
                      className="progress-bar progress-animated bg-white"
                      style={{ width: "50%" }}
                    ></div>
                  </div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
							<div className="widget-stat card bg-danger">
								<div className="card-body p-4">
									<div className="media">
										<span className="me-3">
											<i className="flaticon-381-calendar-1"></i>
										</span>
										<div className="media-body text-white text-end">
											<p className="mb-1">Total Drivers</p>
											<button class="btn btn-primary btn-sm" onClick={()=>openTaskModal('driver')}> Add</button>

											<h3 className="text-white">{user?user.no_of_drivers:''}</h3>
											<div className="progress mb-2 bg-secondary">
                    <div
                      className="progress-bar progress-animated bg-white"
                      style={{ width: "80%" }}
                    ></div>
                  </div>
										</div>
									</div>
								</div>
							</div>
						</div>


					</div>
					
					

				</div>
				<Modal className="fade" show={taskModal}>
                  <Modal.Header>
                    <Modal.Title>Add {label} per amount ${price}
					<h5 className='text-danger'> *you will be charged every month additional to your subscription</h5>
					</Modal.Title>
                    <Button
                      onClick={() => setTaskModal(false)}
                      variant=""
                      className="btn-close"
                    >
                      
                    </Button>
                  </Modal.Header>
                  <Modal.Body>
				  <input type="Number" className="form-control" placeholder="Title" onChange={(e)=>setNumber(e.target.value)}
                                    value={number}
                                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      onClick={() => setTaskModal(false)}
                      variant="danger light"
                    >
                      Close
                    </Button>
                    <Button variant="primary" onClick={() => handleSubmit()}>Submit</Button>
                  </Modal.Footer>
                </Modal>
				
			</div>

		</>
	)
}
export default Home;