import React from 'react';
//import { useNavigate } from "react-router-dom";


import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
} from '../../services/AuthService';


export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';



export function signupAction(email, password, navigate) {
	
    return (dispatch) => {
        signUp(email, password)
        .then((response) => {
            saveTokenInLocalStorage(response.data);
            runLogoutTimer(
                dispatch,
                response.data.expiresIn * 1000,
                //history,
            );
            dispatch(confirmedSignupAction(response.data));
            navigate('/dashboard');
			//history.push('/dashboard');
        })
        .catch((error) => {
            const errorMessage = formatError(error.response.data);
            dispatch(signupFailedAction(errorMessage));
        });
    };
}

export function Logout(navigate) {
	localStorage.removeItem('userDetails');
    navigate('/login');
	//history.push('/login');
    
	return {
        type: LOGOUT_ACTION,
    };
}

export function loginAction(email, password, navigate) {
    return (dispatch) => {
        saveTokenInLocalStorage(
            {
                "kind": "identitytoolkit#VerifyPasswordResponse",
                "localId": "qmt6dRyipIad8UCc0QpMV2MENSy1",
                "email": "demo@example.com",
                "displayName": "",
                "idToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6ImExODE4ZjQ0ODk0MjI1ZjQ2MWQyMmI1NjA4NDcyMDM3MTc2MGY1OWIiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vcmVhY3QtY291cnNlLWI3OThlIiwiYXVkIjoicmVhY3QtY291cnNlLWI3OThlIiwiYXV0aF90aW1lIjoxNzA5MTUwNDc2LCJ1c2VyX2lkIjoicW10NmRSeWlwSWFkOFVDYzBRcE1WMk1FTlN5MSIsInN1YiI6InFtdDZkUnlpcElhZDhVQ2MwUXBNVjJNRU5TeTEiLCJpYXQiOjE3MDkxNTA0NzYsImV4cCI6MTcwOTE1NDA3NiwiZW1haWwiOiJkZW1vQGV4YW1wbGUuY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbImRlbW9AZXhhbXBsZS5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwYXNzd29yZCJ9fQ.aAn2k8BMll8Y-J9J1fzDb05V3e1J4K3IOAYwUnOsxi8IoAdj2BCichU-tuW0pI9NzQQpsQAFd3ZXSHaBvIwfS01Km22VPfAilJSYDEZkuyfcck-VpmSROOWDWz792rBVfMECraqbB2F4TkI_qA5WS6P-kSP-JBEHMBiurmpuhaBW8lkUMiKLTAPYxNJXtXNrAOaDmLqwFypT-jVlSxdVdyMacxRVqhfvVkjlOyY6wv1XCAORBwHG-_2cM4Ky3AeAJL56wGA0JNz3aFUVGSTi-5JWNP6wT1rtuMhDQy_zRiIJTRR03p-1rCQ_pZk7oSg81AfGYWfMfreK66qhbu9_-g",
                "registered": true,
                "refreshToken": "AMf-vBwyTGJqPqwTSf5g9aCZghiDMKaJ6fauj3bXEJrx5FLQem7jQb0fNltMjm4jYhHJkfSK6iu_KlzQXmTbFBm5LDOx3SyHKGy80-lGvEsZwdc-yPOHIljGclxntbysVYeZjziWdQwvY-Jy0YHAq4I6EX5RH6iqULkfoEQT-OlP8v3s6tSGCZoUC5SufLB2NlMbiJGKNKqsMOvRHj5cX7EDTknhlCyVcQ",
                "expiresIn": "3600"
            }
        );
        runLogoutTimer(
            dispatch,
            3600 * 1000,
            navigate,
        );
       dispatch(loginConfirmedAction({
        "kind": "identitytoolkit#VerifyPasswordResponse",
        "localId": "qmt6dRyipIad8UCc0QpMV2MENSy1",
        "email": "demo@example.com",
        "displayName": "",
        "idToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6ImExODE4ZjQ0ODk0MjI1ZjQ2MWQyMmI1NjA4NDcyMDM3MTc2MGY1OWIiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vcmVhY3QtY291cnNlLWI3OThlIiwiYXVkIjoicmVhY3QtY291cnNlLWI3OThlIiwiYXV0aF90aW1lIjoxNzA5MTUwNDc2LCJ1c2VyX2lkIjoicW10NmRSeWlwSWFkOFVDYzBRcE1WMk1FTlN5MSIsInN1YiI6InFtdDZkUnlpcElhZDhVQ2MwUXBNVjJNRU5TeTEiLCJpYXQiOjE3MDkxNTA0NzYsImV4cCI6MTcwOTE1NDA3NiwiZW1haWwiOiJkZW1vQGV4YW1wbGUuY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbImRlbW9AZXhhbXBsZS5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwYXNzd29yZCJ9fQ.aAn2k8BMll8Y-J9J1fzDb05V3e1J4K3IOAYwUnOsxi8IoAdj2BCichU-tuW0pI9NzQQpsQAFd3ZXSHaBvIwfS01Km22VPfAilJSYDEZkuyfcck-VpmSROOWDWz792rBVfMECraqbB2F4TkI_qA5WS6P-kSP-JBEHMBiurmpuhaBW8lkUMiKLTAPYxNJXtXNrAOaDmLqwFypT-jVlSxdVdyMacxRVqhfvVkjlOyY6wv1XCAORBwHG-_2cM4Ky3AeAJL56wGA0JNz3aFUVGSTi-5JWNP6wT1rtuMhDQy_zRiIJTRR03p-1rCQ_pZk7oSg81AfGYWfMfreK66qhbu9_-g",
        "registered": true,
        "refreshToken": "AMf-vBwyTGJqPqwTSf5g9aCZghiDMKaJ6fauj3bXEJrx5FLQem7jQb0fNltMjm4jYhHJkfSK6iu_KlzQXmTbFBm5LDOx3SyHKGy80-lGvEsZwdc-yPOHIljGclxntbysVYeZjziWdQwvY-Jy0YHAq4I6EX5RH6iqULkfoEQT-OlP8v3s6tSGCZoUC5SufLB2NlMbiJGKNKqsMOvRHj5cX7EDTknhlCyVcQ",
        "expiresIn": "3600"
    }));
       //console.log('kk------1');
       //console.log(kk);
       //console.log(response.data);
       //console.log('kk------2');
       //return response.data;
        //return 'success';
        //history.push('/dashboard');                
        // navigate('/dashboard'); 
        
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
