import React, { useReducer,useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import swal from "sweetalert";	
import Select from 'react-select';
import { Collapse } from 'react-bootstrap';

import NoImage from '../../../images/no-image.jpg';
import CkEditorBlog from '../Forms/CkEditor/CkEditorBlog';
import { useAuthContext } from '../../../hooks/useAuthContext';

const options = [
    //{ value: '1', label: 'Select Status' },
    { value: '2', label: 'admin@gmail.com' },
    { value: '3', label: 'India' },
    { value: '4', label: 'Information' },
    { value: '5', label: 'New Menu' },
    { value: '6', label: 'Page Menu' }
]

const options2 = [
    { value: '1', label: 'Published' },
    { value: '2', label: 'Draft' },
    { value: '3', label: 'Trash' },
    { value: '4', label: 'Private' },
    { value: '5', label: 'Pending' }
]

const options3 = [
    { value: '1', label: 'Privacy Policy' },
    { value: '2', label: 'Contact Us' },
    { value: '3', label: 'Important Information' },
    { value: '4', label: 'Free shipping' },
    { value: '5', label: 'Daily Gifts' },
    { value: '6', label: '477 505 8877' },
    { value: '7', label: 'About Us' },
    { value: '8', label: 'Dummy Co' }
]

const options4 = [
    { value: '1', label: 'Active' },
    { value: '2', label: 'InActive' },
]
const options5 = [
    { value: '1', label: 'Monthly' },
    { value: '2', label: 'Yearly' },
]
const options6 = [
    { value: '1', label: '1 year historical analysis' },
    { value: '2', label: '7 year historical analysis' },
]



const initialState = true;
const reducer = (state, action) => {
    switch (action.type) {
        case 'collpase0':
            return { ...state, collpase0: !state.collpase0 }
        case 'collpase1':
            return { ...state, collpase1: !state.collpase1 }
        case 'collpase2':
            return { ...state, collpase2: !state.collpase2 }
        case 'collpase3':
            return { ...state, collpase3: !state.collpase3 }
        case 'collpase4':
            return { ...state, collpase4: !state.collpase4 }
        case 'collpase5':
            return { ...state, collpase5: !state.collpase5 }
        case 'collpase6':
            return { ...state, collpase6: !state.collpase6 }
        case 'collpase7':
            return { ...state, collpase7: !state.collpase7 }
        case 'collpase8':
            return { ...state, collpase8: !state.collpase8 }
        case 'collpase9':
            return { ...state, collpase9: !state.collpase9 }
        case 'section1':
            return { ...state, section1: !state.section1 }
        case 'section2':
            return { ...state, section2: !state.section2 }
        case 'section3':
            return { ...state, section3: !state.section3 }
        case 'section4':
            return { ...state, section4: !state.section4 }
        case 'section5':
            return { ...state, section5: !state.section5 }
        case 'section6':
            return { ...state, section6: !state.section6 }
        case 'section7':
            return { ...state, section7: !state.section7 }
        case 'section8':
            return { ...state, section8: !state.section8 }
        case 'section9':
            return { ...state, section9: !state.section9 }
        default:
            return state
    }
}

const screenOption = [
    { id: "1", title: 'Custom Fields', series: '14' },
    { id: "2", title: 'Discussion', series: '15' },
    { id: "3", title: 'Slug', series: '16' },
    { id: "4", title: 'Author', series: '17' },
    { id: "5", title: 'Seo', series: '19' },
    { id: "6", title: 'Published', series: '13' },
    { id: "7", title: 'Page Attributes', series: '11' },
    { id: "8", title: 'Page Type', series: '18' },
    { id: "9", title: 'Featured Image', series: '12' },

];
const screenOption1 = [
    { id: "1", title: 'Photo Proof of Delivery', series: '14' },
    { id: "2", title: 'Email Support', series: '15' },
    { id: "3", title: 'ETA', series: '16' },
    { id: "4", title: 'Import/Export Deliveries', series: '17' },
    { id: "5", title: 'Basic Route Optimization', series: '19' },
    { id: "6", title: 'Advance Route Optimization', series: '13' },
    { id: "7", title: 'Custom Messaging', series: '11' },
    { id: "8", title: 'Live Driver Tracking', series: '18' },
    { id: "9", title: 'Text Messaging Charge Support in arears', series: '12' },

];

const ContentAdd = () => {
    const [id,setId] = useState(0)
    const [task_price,setTaskPrice] = useState(0)
    const [driver_price,setDriverPrice] = useState(0)
    const [admin_price,setAdminPrice] = useState(0)
    const [dispatcher_price,setDispatcherPrie] = useState(0)
    const [text_messaging_price,setTextMessagingPrice] = useState(0)
    const {dispatch,user} = useAuthContext()

    useEffect(() => {
        const fetchFeature = async () => {
            const response = await fetch('/api/feature/')
            const json = await response.json();
            if(response.ok){
               
                setId(json._id)
                setTaskPrice(json.task_price)
                setDriverPrice(json.driver_price)
                setAdminPrice(json.admin_price)
                setDispatcherPrie(json.dispatcher_price)
                setTextMessagingPrice(json.text_messaging_price)
               
                // console.log(json);
            }
            
        }
        fetchFeature()
    }, []);
    const handleSubmit = async(e) =>{
        e.preventDefault()
        console.log(id);

        const feature = {id,task_price,driver_price,admin_price,dispatcher_price,text_messaging_price}

        const response = await fetch('/api/feature/',{
            method:'POST',
            body:JSON.stringify(feature),
            headers:{
                'Content-Type':'application/json',
                'Authorization':`Bearer ${user.token}`
            }
        })

        const json = await response.json();
        if(!response.ok){
            // setError(json.error)
        
            swal(json.error,'','error');
        }

        if(response.ok){
            // setError(null)
                 // save the user to local storage
    //   localStorage.deleteItem('user')
    //   localStorage.setItem('user', JSON.stringify(json))

      // update the auth context
    
            swal("Feature Updated Successfully",'','success');

        }
    }
  
    return (
        <>

            <div className="row">
                <div className="col-xl-12">
                    <div className="row page-titles mt-3">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={"#"}>Home</Link></li>
                            <li className="breadcrumb-item active">Feature Prices</li>
                        </ol>
                    </div>


                    <div className="row">
                        <div class="card">
                            <div className="card-header">
                                <h4 className="card-title">Feature Setting</h4>
                            </div>
                            <div class="card-body">
                            <div className="col-xl-8">
                                <form>
                                    <input type="hidden" value={id} />
                                    <div className="mb-3">
                                        <label className="from-label">Per Additional Task Price</label>
                                        <input type="number" className="form-control" placeholder="Per Task Price" onChange={(e)=>setTaskPrice(e.target.value)}
                                    value={task_price} />
                                    </div>
                                    <div className="mb-3">
                                        <label className="from-label">Per Additional Driver Price</label>
                                        <input type="number" className="form-control" placeholder="Per Driver Price" onChange={(e)=>setDriverPrice(e.target.value)}
                                    value={driver_price} />
                                    </div>
                                    <div className="mb-3">
                                        <label className="from-label">Per Additional Admin Price</label>
                                        <input type="number" className="form-control" placeholder="Per Admin Price" onChange={(e)=>setAdminPrice(e.target.value)}
                                    value={admin_price} />
                                    </div>
                                    <div className="mb-3">
                                        <label className="from-label">Per Additional Dispatcher Price</label>
                                        <input type="number" className="form-control" placeholder="Per Dispatcher Price" onChange={(e)=>setDispatcherPrie(e.target.value)}
                                    value={dispatcher_price} />
                                    </div>
                                    <div className="mb-3">
                                        <label className="from-label">Text Messaging Multiplier charges </label>
                                        <input type="number" className="form-control" placeholder="Text Messaging charges" onChange={(e)=>setTextMessagingPrice(e.target.value)}
                                    value={text_messaging_price} />
                                    </div>

                                </form>


                                <div className="card-footer border-0 text-end py-3 ">
                                <button className="btn btn-primary" onClick={handleSubmit}>Save</button>
                                </div>

                            </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    );
};

export default ContentAdd;
