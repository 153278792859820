import { useState } from 'react'
import { useAuthContext } from './useAuthContext'

import swal from "sweetalert";	
import { loadingToggleAction,loginAction,
} from '../store/actions/AuthActions';
import { Link, useNavigate } from 'react-router-dom'
import {
  formatError,
  login,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
} from '../services/AuthService';


export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';




export const useLogin = () => {
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const { dispatch } = useAuthContext()
  const Navigate =  useNavigate();


  const loginUser = async (email, password,navigate) => {
    setIsLoading(true)
    setError(null)

    const response = await fetch('/api/superadmin/login', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ email, password })
    })
    const json = await response.json()

    if (!response.ok) {
      setIsLoading(false)
      setError(json.error)
      swal(json.error,'','error')
    }
    if (response.ok) {
      // save the user to local storage
      localStorage.setItem('user', JSON.stringify(json))

      // update the auth context
      dispatch({type: 'LOGIN', payload: json})

      // update loading state
      setIsLoading(false)
      swal('Login Successful','','success')
    
      
      
      saveTokenInLocalStorage(
       json
    );
    runLogoutTimer(
        dispatch,
        3600 * 1000,
        navigate,
    );
   dispatch(loginConfirmedAction(json));

navigate('/dashboard');     
window.location.reload();
    }
  }

  return { loginUser, isLoading, error }
}

export function loginConfirmedAction(data) {
  return {
      type: LOGIN_CONFIRMED_ACTION,
      payload: data,
  };
}

