import React, {useState, useRef, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Select from 'react-select';
import Collapse from 'react-bootstrap/Collapse';

const options = [
    //{ value: '1', label: 'Select Status' },
    { value: '2', label: 'Published' },
    { value: '3', label: 'Draft' },
    { value: '4', label: 'Trash' },
    { value: '5', label: 'Private' },
    { value: '6', label: 'Pending' }
]


const tableData = [
    {number:"1", title:"Privacy Policy"},
    {number:"2", title:"Contact Us"},
    {number:"3", title:"Price"},
    {number:"4", title:"Blog"},
    {number:"5", title:"Faq"},
    {number:"6", title:"About us"},
    {number:"7", title:"Portfolio"},
    {number:"8", title:"Schedule"},
    {number:"9", title:"Under Maintenance"},
    {number:"10", title:"Comming Soon"},
    {number:"11", title:"Faq"},
    {number:"12", title:"About us"},
    {number:"13", title:"Portfolio"},
];

const Content = () =>{
    const [open, setOpen] = useState(true);
    const [open2, setOpen2] = useState(true);

    const [data, setData] = useState(
		document.querySelectorAll("#content_wrapper tbody tr")
	);
	const sort = 8;
	const activePag = useRef(0);
	const [test, settest] = useState(0);
	const [contacts, setContacts] = useState();

	// Active data
	const chageData = (frist, sec) => {
		for (var i = 0; i < data.length; ++i) {
			if (i >= frist && i < sec) {
				data[i].classList.remove("d-none");
			} else {
				data[i].classList.add("d-none");
			}
		}
	};
   // use effect
   useEffect(() => {
    const fetchContacts = async () => {
        const response = await fetch('/api/contact')
        const json = await response.json();
        if(response.ok){
           setContacts(json);
           
        }
        
    }
    fetchContacts()
	}, []);

  
   // Active pagginarion
   activePag.current === 0 && chageData(0, sort);
   // paggination
   let paggination = Array(Math.ceil(data.length / sort))
      .fill()
      .map((_, i) => i + 1);

   // Active paggination & chage data
	const onClick = (i) => {
		activePag.current = i;
		chageData(activePag.current * sort, (activePag.current + 1) * sort);
		settest(i);
	};
   
	// const chackbox = document.querySelectorAll(".sorting_1 input");
	// const motherChackBox = document.querySelector(".sorting_asc input");
    //     const chackboxFun = (type) => {
    //     for (let i = 0; i < chackbox.length; i++) {
    //         const element = chackbox[i];
    //         if (type === "all") {
    //             if (motherChackBox.checked) {
    //             element.checked = true;
    //             } else {
    //             element.checked = false;
    //             }
    //         } else {
    //             if (!element.checked) {
    //             motherChackBox.checked = false;
    //             break;
    //             } else {
    //             motherChackBox.checked = true;
    //             }
    //         }
    //     }
    // };
    const [deleteItem, setDeleteItem] = useState(tableData);
    const handleDelete = ind => {
        setDeleteItem(oldValues => {
          return oldValues.filter((_, i) => i !== ind)
        })
    }
    return(
        <>
            <div className="row">
                <div className="col-xl-12">
                  
                    <div className="filter cm-content-box box-primary mt-5">
                        <div className={`content-title ${open2 ? "" : "collbord"  }`}>
                            <div className="cpa">
                                <i className="fas fa-file-word me-2"></i>Contact List
                            </div>
                            <div className="tools">
                                <Link to={"#"} className="expand SlideToolHeader"
                                     onClick={() => setOpen2(!open2)}
                                >
                                    <i className="fas fa-angle-up"></i>
                                </Link>
                            </div>
                        </div>
                        <Collapse in={open2}>
                            <div className="cm-content-body form excerpt">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <div id="content_wrapper" className="dataTables_wrapper no-footer">
                                            <table className="table table-bordered table-responsive-lg table-striped table-condensed flip-content">
                                                <thead>
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Subject</th>
                                                        <th>Description</th>
                                                        <th>Created At</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {contacts && contacts.map((contact,i)=>(
                                                        <tr key={contact._id}>
                                                            <td>{i+1}</td>
                                                            <td>{contact.name}</td>
                                                            <td>{contact.email}</td>
                                                            <td>{contact.subject}</td>
                                                            <td>{contact.description}</td>
                                                            <td>{contact.createdAt}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                                                              
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Collapse> 
                    </div>
                </div>
            </div>
        </>
    )
}
export default Content;