import React, { useReducer } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Collapse } from 'react-bootstrap';

import NoImage from '../../../images/no-image.jpg';
import CkEditorBlog from '../Forms/CkEditor/CkEditorBlog';

const options = [
    //{ value: '1', label: 'Select Status' },
    { value: '2', label: 'admin@gmail.com' },
    { value: '3', label: 'India' },
    { value: '4', label: 'Information' },
    { value: '5', label: 'New Menu' },
    { value: '6', label: 'Page Menu' }
]

const options2 = [
    { value: '1', label: 'Published' },
    { value: '2', label: 'Draft' },
    { value: '3', label: 'Trash' },
    { value: '4', label: 'Private' },
    { value: '5', label: 'Pending' }
]

const options3 = [
    { value: '1', label: 'Privacy Policy' },
    { value: '2', label: 'Contact Us' },
    { value: '3', label: 'Important Information' },
    { value: '4', label: 'Free shipping' },
    { value: '5', label: 'Daily Gifts' },
    { value: '6', label: '477 505 8877' },
    { value: '7', label: 'About Us' },
    { value: '8', label: 'Dummy Co' }
]

const options4 = [
    { value: '1', label: 'Active' },
    { value: '2', label: 'InActive' },
]
const options5 = [
    { value: '1', label: 'Monthly' },
    { value: '2', label: 'Yearly' },
]
const options6 = [
    { value: '1', label: '1 year historical analysis' },
    { value: '2', label: '7 year historical analysis' },
]



const initialState = true;
const reducer = (state, action) => {
    switch (action.type) {
        case 'collpase0':
            return { ...state, collpase0: !state.collpase0 }
        case 'collpase1':
            return { ...state, collpase1: !state.collpase1 }
        case 'collpase2':
            return { ...state, collpase2: !state.collpase2 }
        case 'collpase3':
            return { ...state, collpase3: !state.collpase3 }
        case 'collpase4':
            return { ...state, collpase4: !state.collpase4 }
        case 'collpase5':
            return { ...state, collpase5: !state.collpase5 }
        case 'collpase6':
            return { ...state, collpase6: !state.collpase6 }
        case 'collpase7':
            return { ...state, collpase7: !state.collpase7 }
        case 'collpase8':
            return { ...state, collpase8: !state.collpase8 }
        case 'collpase9':
            return { ...state, collpase9: !state.collpase9 }
        case 'section1':
            return { ...state, section1: !state.section1 }
        case 'section2':
            return { ...state, section2: !state.section2 }
        case 'section3':
            return { ...state, section3: !state.section3 }
        case 'section4':
            return { ...state, section4: !state.section4 }
        case 'section5':
            return { ...state, section5: !state.section5 }
        case 'section6':
            return { ...state, section6: !state.section6 }
        case 'section7':
            return { ...state, section7: !state.section7 }
        case 'section8':
            return { ...state, section8: !state.section8 }
        case 'section9':
            return { ...state, section9: !state.section9 }
        default:
            return state
    }
}

const screenOption = [
    { id: "1", title: 'Custom Fields', series: '14' },
    { id: "2", title: 'Discussion', series: '15' },
    { id: "3", title: 'Slug', series: '16' },
    { id: "4", title: 'Author', series: '17' },
    { id: "5", title: 'Seo', series: '19' },
    { id: "6", title: 'Published', series: '13' },
    { id: "7", title: 'Page Attributes', series: '11' },
    { id: "8", title: 'Page Type', series: '18' },
    { id: "9", title: 'Featured Image', series: '12' },

];
const screenOption1 = [
    { id: "1", title: 'Photo Proof of Delivery', series: '14' },
    { id: "2", title: 'Email Support', series: '15' },
    { id: "3", title: 'ETA', series: '16' },
    { id: "4", title: 'Import/Export Deliveries', series: '17' },
    { id: "5", title: 'Basic Route Optimization', series: '19' },
    { id: "6", title: 'Advance Route Optimization', series: '13' },
    { id: "7", title: 'Custom Messaging', series: '11' },
    { id: "8", title: 'Live Driver Tracking', series: '18' },
    { id: "9", title: 'Text Messaging Charge Support in arears', series: '12' },

];

const ContentAdd = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    // function addClass() {
    //     setTimeout(()=>{
    //     let showData = document.querySelector('.heading');            
    //         return showData.classList.toggle("show");           
    //     },100)
    // }  

    const [file, setFile] = React.useState(null)
    const fileHandler = (e) => {
        setFile(e.target.files[0]);
    }
    return (
        <>

            <div className="row">
                <div className="col-xl-12">
                    <div className="row page-titles mt-3">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={"#"}>Home</Link></li>
                            <li className="breadcrumb-item active">Stripe Settings</li>
                        </ol>
                    </div>

                    <div className="row">
                        <div class="card">
                            <div className="card-header">
                                <h4 className="card-title">Stripe Setting</h4>
                            </div>
                            <div class="card-body">
                                <div className="col-xl-8">
                                    <form>
                                        <div className="mb-3">
                                            <label className="from-label">Publishable Key</label>
                                            <input type="text" className="form-control" placeholder="Publishable key" />
                                        </div>
                                    </form>
                                    <form>
                                        <div className="mb-3">
                                            <label className="from-label">Secret Key</label>
                                            <input type="text" className="form-control" placeholder="Secret Key" />
                                        </div>
                                    </form>

                                    <div className="card-footer border-0 text-end py-3 ">
                                        <Link to={"#"} className="btn btn-primary">Save</Link>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    );
};

export default ContentAdd;
