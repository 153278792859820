import React, {useState, useRef, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Select from 'react-select';
import Collapse from 'react-bootstrap/Collapse';
import { usePlansContext } from '../../../hooks/usePlansContext';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";




const tableData = [
    {number:"1", title:"Trial"},
    {number:"2", title:"Basic"},
    {number:"3", title:"Intermediate"},
    {number:"4", title:"Advance"},
];

const PageList = ({}) =>{
    const [modalCentered, setModalCentered] = useState(false);
    const [page, setPage] = useState('');

    const [open, setOpen] = useState(true);
    const [open2, setOpen2] = useState(true);

    const [data, setData] = useState(
		document.querySelectorAll("#content_wrapper tbody tr")
	);
	const sort = 8;
	const activePag = useRef(0);
	const [test, settest] = useState(0);

	// Active data
	const chageData = (frist, sec) => {
		for (var i = 0; i < data.length; ++i) {
			if (i >= frist && i < sec) {
				data[i].classList.remove("d-none");
			} else {
				data[i].classList.add("d-none");
			}
		}
	};

    // const {plans, dispatch} = usePlansContext()
    const {user} = useAuthContext()

    const [pages,setPages] = useState([])


   // use effect
   useEffect(() => {
   
    fetchPages()
}, []);
const fetchPages = async () => {
    const response = await fetch('/api/pages',{
        headers:{'Authorization':`Bearer ${user.token}`}
    })
    const json = await response.json();
    if(response.ok){
        setPages(json);
       
    }
    
}

   

  
   // Active pagginarion
   activePag.current === 0 && chageData(0, sort);
   // paggination
   let paggination = Array(Math.ceil(data.length / sort))
      .fill()
      .map((_, i) => i + 1);

   // Active paggination & chage data
	const onClick = (i) => {
		activePag.current = i;
		chageData(activePag.current * sort, (activePag.current + 1) * sort);
		settest(i);
	};
  
    const [deleteItem, setDeleteItem] = useState(tableData);
    const handleDelete = async() => {
      
       const response = await fetch('/api/pages/'+page._id, {
        headers:{'Authorization':`Bearer ${user.token}`},
        method: 'DELETE'
       })
       const json = await response.json()

       if(response.ok){
        fetchPages();
                setModalCentered(false)

       }
    }
    const openDeleteModal = async(page) => {
        setModalCentered(true)
        setPage(page)
      
    }
    return(
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="mb-3">
                        <Link to={"/add-page"} className="btn btn-primary">Add Page</Link>
                    </div>
                    <div className="filter cm-content-box box-primary mt-5">
                        <div className={`content-title ${open2 ? "" : "collbord"  }`}>
                            <div className="cpa">
                               Pages List
                            </div>
                            <div className="tools">
                                <Link to={"#"} className="expand SlideToolHeader"
                                     onClick={() => setOpen2(!open2)}
                                >
                                    <i className="fas fa-angle-up"></i>
                                </Link>
                            </div>
                        </div>
                        <Collapse in={open2}>
                            <div className="cm-content-body form excerpt">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <div id="content_wrapper" className="dataTables_wrapper no-footer">
                                            <table className="table table-bordered table-responsive-lg table-striped table-condensed flip-content">
                                                <thead>
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>Title</th>
                                                        <th>Status</th>
                                                        <th>Modified</th>
                                                        <th className="text-end">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {pages && pages.map((page,i)=>(
                                                        <tr key={page._id}>
                                                            <td>{i+1}</td>
                                                            <td>{page.title}</td>
                                                            <td>{page.status?'active':'inactive'}</td>
                                                            <td>{new Date(page.createdAt).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"}) }</td>
                                                            <td className='text-end'>
                                                                <Link to={"/edit-page/"+page._id} className="btn btn-warning btn-sm content-icon me-1">
                                                                    <i className="fa fa-edit"></i>
                                                                </Link>
                                                                
                                                                <Link to={"#"} className="btn btn-danger btn-sm content-icon ms-1"
                                                                    onClick={()=>openDeleteModal(page)}
                                                                >
                                                                    <i className="fa fa-times"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                                                             
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Collapse> 
                        <Modal className="fade" show={modalCentered}>
                  <Modal.Header>
                    <Modal.Title>Delete Page</Modal.Title>
                    <Button
                      onClick={() => setModalCentered(false)}
                      variant=""
                      className="btn-close"
                    >
                      
                    </Button>
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                     Are you Sure you want to delete?
                    </p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      onClick={() => setModalCentered(false)}
                      variant="danger light"
                    >
                      Close
                    </Button>
                    <Button variant="primary" onClick={() => handleDelete()}>Yes</Button>
                  </Modal.Footer>
                </Modal>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PageList;