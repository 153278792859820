import React, { useReducer, useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { Collapse } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import swal from "sweetalert";	
import { usePlansContext } from '../../../hooks/usePlansContext';
import { useAuthContext } from '../../../hooks/useAuthContext';


import NoImage from '../../../images/no-image.jpg';
import CkEditorBlog from '../Forms/CkEditor/CkEditorBlog';

const options = [
    //{ value: '1', label: 'Select Status' },
    { value: '2', label: 'admin@gmail.com' },
    { value: '3', label: 'India' },
    { value: '4', label: 'Information' },
    { value: '5', label: 'New Menu' },
    { value: '6', label: 'Page Menu' }
]

const options2 = [
    { value: '1', label: 'Published' },
    { value: '2', label: 'Draft' },
    { value: '3', label: 'Trash' },
    { value: '4', label: 'Private' },
    { value: '5', label: 'Pending' }
]

const options3 = [
    { value: '1', label: 'Privacy Policy' },
    { value: '2', label: 'Contact Us' },
    { value: '3', label: 'Important Information' },
    { value: '4', label: 'Free shipping' },
    { value: '5', label: 'Daily Gifts' },
    { value: '6', label: '477 505 8877' },
    { value: '7', label: 'About Us' },
    { value: '8', label: 'Dummy Co' }
]

const options4 = [
    { value: 0, label: 'Active' },
    { value: 1, label: 'InActive' },
]
const options5 = [
    { value: '1', label: 'Monthly' },
    { value: '2', label: 'Yearly' },
]
const options6 = [
    { value: '1', label: '1 year historical analysis' },
    { value: '2', label: '7 year historical analysis' },
]



const initialState = true;
const reducer = (state, action) => {
    switch (action.type) {
        case 'collpase0':
            return { ...state, collpase0: !state.collpase0 }
        case 'collpase1':
            return { ...state, collpase1: !state.collpase1 }
        case 'collpase2':
            return { ...state, collpase2: !state.collpase2 }
        case 'collpase3':
            return { ...state, collpase3: !state.collpase3 }
        case 'collpase4':
            return { ...state, collpase4: !state.collpase4 }
        case 'collpase5':
            return { ...state, collpase5: !state.collpase5 }
        case 'collpase6':
            return { ...state, collpase6: !state.collpase6 }
        case 'collpase7':
            return { ...state, collpase7: !state.collpase7 }
        case 'collpase8':
            return { ...state, collpase8: !state.collpase8 }
        case 'collpase9':
            return { ...state, collpase9: !state.collpase9 }
        case 'section1':
            return { ...state, section1: !state.section1 }
        case 'section2':
            return { ...state, section2: !state.section2 }
        case 'section3':
            return { ...state, section3: !state.section3 }
        case 'section4':
            return { ...state, section4: !state.section4 }
        case 'section5':
            return { ...state, section5: !state.section5 }
        case 'section6':
            return { ...state, section6: !state.section6 }
        case 'section7':
            return { ...state, section7: !state.section7 }
        case 'section8':
            return { ...state, section8: !state.section8 }
        case 'section9':
            return { ...state, section9: !state.section9 }
        default:
            return state
    }
}

const screenOption = [
    { id: "1", title: 'Custom Fields', series: '14' },
    { id: "2", title: 'Discussion', series: '15' },
    { id: "3", title: 'Slug', series: '16' },
    { id: "4", title: 'Author', series: '17' },
    { id: "5", title: 'Seo', series: '19' },
    { id: "6", title: 'Published', series: '13' },
    { id: "7", title: 'Page Attributes', series: '11' },
    { id: "8", title: 'Page Type', series: '18' },
    { id: "9", title: 'Featured Image', series: '12' },

];
const screenOption1 = [
    { id: "1", title: 'Photo Proof of Delivery', series: '14', name:'photo_proof' },
    { id: "2", title: 'Email Support', series: '15' },
    { id: "3", title: 'ETA', series: '16' },
    { id: "10", title: 'Task Completion Customization', series: '16' },
    { id: "11", title: 'Delivery Status', series: '16' },
    { id: "4", title: 'Import/Export Deliveries', series: '17' },
    { id: "5", title: 'Basic Route Optimization', series: '19' },
    { id: "6", title: 'Advance Route Optimization', series: '13' },
    { id: "7", title: 'Custom Messaging', series: '11' },
    { id: "8", title: 'Live Driver Tracking', series: '18' },
    { id: "9", title: 'Text Messaging Charge Support in arears', series: '12' },

];

const PlanAdd = () => {
    const [title,setTitle] = useState('')
    const [status,setStatus] = useState(true)
    const [description,setDescription] = useState('')
    const [monthly_plan_price,setmonthlyPlanPrice] = useState('')
    const [yearly_plan_price,setyearlyPlanPrice] = useState('')
    const [plan_price,setPlanPrice] = useState('')
    const [day_limit,setDayLimit] = useState('')
    const [no_of_tasks,setNoofTasks] = useState('')
    const [no_of_drivers,setNoofDrivers] = useState('')
    const [no_of_dispatchers,setNoofDispatchers] = useState('')
    const [no_of_admins,setNoofAdmins] = useState('')
    const [no_of_teams,setNoofTeams] = useState('')
    const [no_of_hubs,setNoofHubs] = useState('')
    const [historical_analysis,setHistoricalAnalysis] = useState('')
    const [plan_order,setPlanOrder] = useState('')
    const [year_month,setYearMonth] = useState('month')
   
    const [features, setFeatures] = useState({
    photo_proof:false,
    email_support:false,
    quick_route_organize:false,
    basic_route_optimization:false,
    advance_route_optimization:false,
    basic_eta_notification:false,
    advance_eta_notification:false,
    api_access:false,
    delivery_status:false,
    task_completion_customization:false,
    private_label_recipient_tracking_page:false,
    onboarding_and_support:false,
    premium_onboarding:false,
    sla:false,
    enterprise_sso:false,
    multibrand_region_support:false,
    bulk_sms:false,
    unlimited_users:false,
    twilio_messaging:false
    })
    const [error,setError] = useState(null)
    const {dispatch} = usePlansContext()
    const {user} = useAuthContext()
    let navigate = useNavigate();

     const [state] = useReducer(reducer, initialState);
    // function addClass() {
    //     setTimeout(()=>{
    //     let showData = document.querySelector('.heading');            
    //         return showData.classList.toggle("show");           
    //     },100)
    // }  

    const handleSubmit = async(e) =>{
        e.preventDefault()
        console.log(user);

        const plan = {title,status,description,monthly_plan_price,yearly_plan_price, day_limit,no_of_tasks, no_of_drivers, no_of_dispatchers, no_of_admins, historical_analysis, no_of_hubs,no_of_teams
            ,plan_order, features,plan_price,year_month
        }

        const response = await fetch('/api/plans',{
            method:'POST',
            body:JSON.stringify(plan),
            headers:{
                'Content-Type':'application/json',
                'Authorization':`Bearer ${user.token}`
            }
        })

        const json = await response.json();
        if(!response.ok){
            // setError(json.error)
            swal(json.error,'','error');
        }

        if(response.ok){
            swal("Plan Added Successfully",'','success');
            navigate('/plans')
        }
    }

    const [file, setFile] = React.useState(null)
    const fileHandler = (e) => {
        setFile(e.target.files[0]);
    }
    console.log("features", features)
    return (
        <>

            <div className="row">
                <div className="col-xl-12">
                    <div className="row page-titles mt-3">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={"#"}>Home</Link></li>
                            <li className="breadcrumb-item"><Link to={"#"}>Plan</Link></li>
                            <li className="breadcrumb-item active">Add</li>
                        </ol>
                    </div>
                    <div>
                        <Link to={"/plans"} className="btn btn-primary mb-3">Plan List</Link>{" "}
                    </div>

                    <div className="row">
                        <div className="col-xl-8">
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label className="from-label">Title</label>
                                    <input type="text" className="form-control" placeholder="Title" onChange={(e)=>setTitle(e.target.value)}
                                    value={title}
                                    />
                                </div>
                          
                            <div className="custom-ekeditor cms-radius add-content-ckeditor mb-3">
                            <CKEditor
                editor={ ClassicEditor }
            // data="<p>Hello from CKEditor 5!</p>"
                onReady={ editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log( 'Editor is ready to use!', editor );
                } }
                onChange={ ( event, editor ) => {
                    const data = editor.getData();
                    setDescription(data);
                    console.log( { event, editor, data } );
                } }
                onBlur={ ( event, editor ) => {
                    console.log( 'Blur.', editor );
                } }
                onFocus={ ( event, editor ) => {
                    console.log( 'Focus.', editor );
                } }
            /> 
                            </div>


                            <div className="mb-3">
                                <label className="from-label">Status</label>
                                <select className="form-control" onChange={(e)=>setStatus(e.target.value)} defaultValue={status} >
                                <option value="0">inactive</option>
          <option value="1">active</option>
                                    </select>
                            </div>
                            <div className="mb-3">
                                <label className="from-label">Select Year/Month</label>
                                <select className="form-control" onChange={(e)=>setYearMonth(e.target.value)} defaultValue={year_month} >
                                <option value="month">Month</option>
          <option value="year">Year</option>
                                    </select>
                            </div>
                            <div className="mb-3">
                                <label className="from-label">Monthly Plan Price</label>
                                <input type="number" className="form-control" placeholder="Price"  onChange={(e)=>setmonthlyPlanPrice(e.target.value)} value={monthly_plan_price}/>
                            </div>

                            <div className="mb-3">
                                <label className="from-label">Yearly Plan Price</label>
                                <input type="number" className="form-control" placeholder="Price" onChange={(e)=>setyearlyPlanPrice(e.target.value)} value={yearly_plan_price}/>
                            </div>
                            <div className="mb-3">
                                <label className="from-label"> Plan Price</label>
                                <input type="number" className="form-control" placeholder="Plan Price" onChange={(e)=>setPlanPrice(e.target.value)} value={plan_price}/>
                            </div>
                            <div className="mb-3">
                                <label className="from-label">Plan Order</label>
                                <input type="number" className="form-control" placeholder="Plan Order"  onChange={(e)=>setPlanOrder(e.target.value)} value={plan_order} />
                            </div>
                            <div className="mb-3">
                                <label className="from-label">Day Limit (optional only for trial plans)</label>
                                <input type="number" className="form-control" placeholder="Day Limit"  onChange={(e)=>setDayLimit(e.target.value)} value={day_limit} />
                            </div>

                            <div className="card-footer border-0 text-end py-3 ">
                                <button className="btn btn-primary">Save</button>
                            </div>
                            </form>

                        </div>
                        <div className="col-xl-4">

                            {!state.section7 &&
                                <div className="filter cm-content-box box-primary">
                                    <div className="content-title">
                                        <div className="cpa">
                                            Features
                                        </div>
                                        <div className="tools">
                                            <Link to={"#"}
                                                className={`SlideToolHeader ${state.collpase4 ? 'collapse' : 'expand'}`}
                                                onClick={() => dispatch({ type: 'collpase4' })}
                                            >
                                                <i className="fas fa-angle-up"></i>
                                            </Link>
                                        </div>
                                    </div>
                                    <Collapse in={!state.collpase4}>
                                        <div className="cm-content-body publish-content form excerpt">
                                            <div className="card-body Cms-selecter">
                                                <div className="mb-3">
                                                    <label className="from-label">No of Tasks</label>
                                                    <input type="number" className="form-control" placeholder="1" onChange={(e)=>setNoofTasks(e.target.value)} value={no_of_tasks} />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="from-label">No of Drivers</label>
                                                    <input type="number" className="form-control" placeholder="1" onChange={(e)=>setNoofDrivers(e.target.value)} value={no_of_drivers} />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="from-label">No of Dispatcher</label>
                                                    <input type="number" className="form-control" placeholder="1" onChange={(e)=>setNoofDispatchers(e.target.value)} value={no_of_dispatchers} />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="from-label">No of Admin</label>
                                                    <input type="number" className="form-control" placeholder="1" onChange={(e)=>setNoofAdmins(e.target.value)} value={no_of_admins} />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="from-label">No of Hubs</label>
                                                    <input type="number" className="form-control" placeholder="1" onChange={(e)=>setNoofHubs(e.target.value)} value={no_of_hubs} />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="from-label">No of Teams</label>
                                                    <input type="number" className="form-control" placeholder="1" onChange={(e)=>setNoofTeams(e.target.value)} value={no_of_teams} />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="from-label">Historical Analysis(optional) in yrs *</label>
                                                    <input type="number" className="form-control" placeholder="1" onChange={(e)=>setHistoricalAnalysis(e.target.value)} value={historical_analysis} />

                                                </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" value={features.photo_proof} id={1}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.photo_proof = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={1}>
                                                               Photo Proof of Delivery
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" value={features.email_support} id={2}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.email_support = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={2}>
                                                              Email Support
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" value={features.quick_route_organize} id={3}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.quick_route_organize = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={3}>
                                                              Quick Route Organize
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" value={features.basic_route_optimization} id={4}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.basic_route_optimization = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={4}>
                                                              Basic Route Optimization
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" value={features.advance_route_optimization} id={5}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.advance_route_optimization = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={5}>
                                                              Advance Route Optimization *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" value={features.basic_eta_notification} id={6}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.basic_eta_notification = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={6}>
                                                              Basic ETA Notification
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" value={features.advance_eta_notification} id={7}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.advance_eta_notification = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={7}>
                                                              Advance ETA Notification *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" value={features.api_access} id={8}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.api_access = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={8}>
                                                             Api Access *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" value={features.delivery_status} id={9}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.delivery_status = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={9}>
                                                            Delivery Status *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" value={features.task_completion_customization} id={10}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.task_completion_customization = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={10}>
                                                           Task Completion Customization *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" value={features.private_label_recipient_tracking_page} id={11}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.private_label_recipient_tracking_page = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={11}>
                                                          Private Label Recipient Tracking Page *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" value={features.onboarding_and_support} id={12}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.onboarding_and_support = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={12}>
                                                         Onboarding And Support *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" value={features.sla} id={13}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.sla = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={13}>
                                                        SLA *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" value={features.enterprise_sso} id={14}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.enterprise_sso = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={14}>
                                                            Enterprise SSO *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" value={features.multibrand_region_support} id={15}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.multibrand_region_support = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={15}>
                                                            MultiBrand Region Support *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" value={features.bulk_sms} id={16}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.bulk_sms = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={16}>
                                                            Bulk SMS
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" value={features.unlimited_users} id={17}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.unlimited_users = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={17}>
                                                            Unlimited Users *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" value={features.twilio_messaging} id={18}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.twilio_messaging = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={18}>
                                                            SMS Messaging 
                                                            </label>
                                                        </div>
                                                    </div>
                                                    
                                                    
                                            </div>

                                        </div>
                                    </Collapse>
                                </div>
                            }

                        </div>
                        
                    </div>
                </div>
            </div>

        </>
    );
};

export default PlanAdd;
