import React, { useReducer,useEffect,useState } from 'react';
import { Link, useParams ,useNavigate} from 'react-router-dom';
import axios from 'axios';

import swal from "sweetalert";

import Select from 'react-select';
import {
	Dropdown, Nav, Tab, Row, Col, Card, Table,
	Badge
} from 'react-bootstrap';


import NoImage from '../../../images/no-image.jpg';
import CkEditorBlog from '../Forms/CkEditor/CkEditorBlog';
import bg1 from "../../../images/big/img1.jpg";
import profile from "../../../images/profile/profile.png";

const options = [
    //{ value: '1', label: 'Select Status' },
    { value: '2', label: 'admin@gmail.com' },
    { value: '3', label: 'India' },
    { value: '4', label: 'Information' },
    { value: '5', label: 'New Menu' },
    { value: '6', label: 'Page Menu' }
]

const options2 = [
    { value: '1', label: 'Published' },
    { value: '2', label: 'Draft' },
    { value: '3', label: 'Trash' },
    { value: '4', label: 'Private' },
    { value: '5', label: 'Pending' }
]

const options3 = [
    { value: '1', label: 'Privacy Policy' },
    { value: '2', label: 'Contact Us' },
    { value: '3', label: 'Important Information' },
    { value: '4', label: 'Free shipping' },
    { value: '5', label: 'Daily Gifts' },
    { value: '6', label: '477 505 8877' },
    { value: '7', label: 'About Us' },
    { value: '8', label: 'Dummy Co' }
]

const options4 = [
    { value: '1', label: 'Active' },
    { value: '2', label: 'InActive' },
]
const options5 = [
    { value: '1', label: 'Monthly' },
    { value: '2', label: 'Yearly' },
]
const options6 = [
    { value: '1', label: '1 year historical analysis' },
    { value: '2', label: '7 year historical analysis' },
]



const initialState = true;
const reducer = (state, action) => {
    switch (action.type) {
        case 'collpase0':
            return { ...state, collpase0: !state.collpase0 }
        case 'collpase1':
            return { ...state, collpase1: !state.collpase1 }
        case 'collpase2':
            return { ...state, collpase2: !state.collpase2 }
        case 'collpase3':
            return { ...state, collpase3: !state.collpase3 }
        case 'collpase4':
            return { ...state, collpase4: !state.collpase4 }
        case 'collpase5':
            return { ...state, collpase5: !state.collpase5 }
        case 'collpase6':
            return { ...state, collpase6: !state.collpase6 }
        case 'collpase7':
            return { ...state, collpase7: !state.collpase7 }
        case 'collpase8':
            return { ...state, collpase8: !state.collpase8 }
        case 'collpase9':
            return { ...state, collpase9: !state.collpase9 }
        case 'section1':
            return { ...state, section1: !state.section1 }
        case 'section2':
            return { ...state, section2: !state.section2 }
        case 'section3':
            return { ...state, section3: !state.section3 }
        case 'section4':
            return { ...state, section4: !state.section4 }
        case 'section5':
            return { ...state, section5: !state.section5 }
        case 'section6':
            return { ...state, section6: !state.section6 }
        case 'section7':
            return { ...state, section7: !state.section7 }
        case 'section8':
            return { ...state, section8: !state.section8 }
        case 'section9':
            return { ...state, section9: !state.section9 }
        default:
            return state
    }
}

const screenOption = [
    { id: "1", title: 'Custom Fields', series: '14' },
    { id: "2", title: 'Discussion', series: '15' },
    { id: "3", title: 'Slug', series: '16' },
    { id: "4", title: 'Author', series: '17' },
    { id: "5", title: 'Seo', series: '19' },
    { id: "6", title: 'Published', series: '13' },
    { id: "7", title: 'Page Attributes', series: '11' },
    { id: "8", title: 'Page Type', series: '18' },
    { id: "9", title: 'Featured Image', series: '12' },

];
const screenOption1 = [
    { id: "1", title: 'Photo Proof of Delivery', series: '14' },
    { id: "2", title: 'Email Support', series: '15' },
    { id: "3", title: 'ETA', series: '16' },
    { id: "10", title: 'Task Completion Customization', series: '16' },
    { id: "11", title: 'Delivery Status', series: '16' },
    { id: "4", title: 'Import/Export Deliveries', series: '17' },
    { id: "5", title: 'Basic Route Optimization', series: '19' },
    { id: "6", title: 'Advance Route Optimization', series: '13' },
    { id: "7", title: 'Custom Messaging', series: '11' },
    { id: "8", title: 'Live Driver Tracking', series: '18' },
    { id: "9", title: 'Text Messaging Charge Support in arears', series: '12' },

];

const ContentAdd = () => {
    const svg1 = (
		<svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<rect x="0" y="0" width="24" height="24"></rect>
				<circle fill="#000000" cx="5" cy="12" r="2"></circle>
				<circle fill="#000000" cx="12" cy="12" r="2"></circle>
				<circle fill="#000000" cx="19" cy="12" r="2"></circle>
			</g>
		</svg>
	);
    const [state, dispatch] = useReducer(reducer, initialState);
    let {id} = useParams();

    const [subscriber, setSubscriber] = React.useState(null)
    const [subscriptions, setSubscriptions] = React.useState([])
    const [smsCharges, setSMSCharges] = React.useState([])
    let navigate = useNavigate();

    const [features, setFeatures] = useState({
        no_of_tasks:'',
        no_of_drivers:'',
        no_of_dispatchers:'',
        no_of_admins:'',
        no_of_teams:'',
        no_of_hubs:'',
        historical_analysis:'',
        photo_proof:false,
        email_support:false,
        quick_route_organize:false,
        basic_route_optimization:false,
        advance_route_optimization:false,
        basic_eta_notification:false,
        advance_eta_notification:false,
        api_access:false,
        delivery_status:false,
        task_completion_customization:false,
        private_label_recipient_tracking_page:false,
        onboarding_and_support:false,
        premium_onboarding:false,
        sla:false,
        enterprise_sso:false,
        multibrand_region_support:false,
        bulk_sms:false,
        unlimited_users:false,
        twilio_messaging:false
        })
       


    useEffect(() => {
        const fetchSubscription = async () => {

            const response = await fetch('/api/stripe/getSubscriptionDetail/'+id)
            // const response = await fetch('/api/plans/')
            const json = await response.json();

            if(response.ok){
               
                setSubscriber(json.user);
                setSubscriptions(json.subscriptions);
                setSMSCharges(json.user.SMSLogs);
                setFeatures(
                    json.user.plan_details
                )
               
            }
            
        }
        fetchSubscription()
    }, []);
    const handleSubmit = async (e) => {
        e.preventDefault()

        const user = {
          features
        }

        const response = await fetch('/api/stripe/updateSubscriptionFeature/' + subscriber._id, {
            method: 'POST',
            body: JSON.stringify(user),
            headers: {
                'Content-Type': 'application/json',
            }
        })

        const json = await response.json();
        if (!response.ok) {
            swal(json.error, '', 'error');
        }

        if (response.ok) {
            swal("Subscription Updated Successfully", '', 'success');
            setSubscriber(json);
            navigate('/subscribers')
        }
    }
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');

    const cancelSubscription = async (subscriptionId) => {
        setIsLoading(true);
        try {
            // Make an API call to your backend to cancel the subscription
            const response = await axios.post('/api/stripe/cancelSubscription', { subscriptionId });
            if(response.ok)
            swal("Cancelled subscription successfully.",'','success');
        } catch (error) {
            console.error('Error canceling subscription:', error);
            swal('Failed to cancel subscription.','','error');
        }
        setIsLoading(false);
    };

    return (
        <>

            <div className="row">
                <div className="col-xl-12">
                    <div className="row page-titles mt-3">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={"#"}>Home</Link></li>
                            <li className="breadcrumb-item active">Subscription Detail</li>
                        </ol>
                    </div>
                    

                    <div className="row">
                        <div className="col-xl-4 col-lg-12 col-sm-12">
                        
                            {subscriber && <div className="card overflow-hidden">
                                <div
                                    className="text-center p-3 overlay-box "
                                    style={{ backgroundImage: `url(${bg1})` }}
                                >
                                   
                                    <div className="profile-photo">
                                    {/* <MDBIcon fas icon="user-circle" /> */}
                                        {/* <img
                                            src={profile}
                                            width="100"
                                            className="m-auto img-fluid rounded-circle d-block"
                                            alt=""
                                        /> */}
                                        
                                    </div>
                                    <h3 className="mt-3 mb-1 text-white">{subscriber.first_name} {subscriber.last_name}</h3>
                                </div>
                                <div>
                                    
            {message && <p>{message}</p>}
        </div>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item d-flex justify-content-between">
                                        <span className="mb-0">Current Plan</span>{" "}
                                        <strong className="text-muted">{subscriber.plan_details.title} Plan</strong>
                                    </li>
                                   
                                    {/* <li className="list-group-item d-flex justify-content-between">
                                        <span className="mb-0">Starts At</span>{" "}
                                        <strong className="text-muted">19/02/2024</strong>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between">
                                        <span className="mb-0">Expires At</span>{" "}
                                        <strong className="text-muted">24/02/2024 </strong>
                                    </li> */}
                                </ul>

                            </div>}
                        </div>
                        <Col lg={12}>
						<Card>
							<Card.Header>
								<Card.Title>Subscription History {subscriber && (!subscriber.status || subscriber.status !== 'cancelled') && (
  <button 
    className="btn btn-danger"
    onClick={() => cancelSubscription(subscriber.stripe_subscription_id)}
    disabled={isLoading}
  >
    {isLoading ? 'Canceling...' : 'Cancel Subscription'}
  </button>
)}</Card.Title>
							</Card.Header>
							<Card.Body>
                            <table className="table table-bordered table-responsive-lg table-striped table-condensed flip-content">
                                                <thead>
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>Price</th>
                                                        <th>Plan</th>
                                                        <th>Start</th>
                                                        <th>End</th>
                                                        <th>Remarks</th>
                                                        <th>Modified</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {subscriptions && subscriptions.map((subscriber,i)=>(
                                                        <tr key={subscriber._id}>
                                                            <td>{i+1}</td>
                                                            <td>${subscriber.price/100}</td>
                                                            <td>{subscriber.plan?subscriber.plan.title:''} </td>
                                                            <td>{new Date(subscriber.period_start).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"}) }</td>
                                                            <td>{new Date(subscriber.period_end).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"}) }</td>
                                                            <td>{subscriber.remarks}</td>
                                                            <td>{new Date(subscriber.createdAt).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"}) }</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
							</Card.Body>
						</Card>
					</Col>
                    <Col lg={12}>
						<Card>
							<Card.Header>
								<Card.Title>Current Features</Card.Title>
							</Card.Header>
							<Card.Body>
                    <div className="cm-content-body publish-content form excerpt">
                                            <div className="card-body Cms-selecter">
                                                <div className="mb-3">
                                                    <label className="from-label">No of Tasks</label>
                                                    <input type="number" className="form-control" placeholder="1" onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.no_of_tasks = e.target.value;
                                                                setFeatures(prev)
                                                            }} value={features.no_of_tasks} />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="from-label">No of Drivers</label>
                                                    <input type="number" className="form-control" placeholder="1"onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.no_of_drivers = e.target.value;
                                                                setFeatures(prev)
                                                            }} value={features.no_of_drivers} />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="from-label">No of Dispatcher</label>
                                                    <input type="number" className="form-control" placeholder="1"onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.no_of_dispatchers = e.target.value;
                                                                setFeatures(prev)
                                                            }} value={features.no_of_dispatchers} />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="from-label">No of Admin</label>
                                                    <input type="number" className="form-control" placeholder="1" onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.no_of_admins = e.target.value;
                                                                setFeatures(prev)
                                                            }} value={features.no_of_admins} />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="from-label">No of Hubs</label>
                                                    <input type="number" className="form-control" placeholder="1" onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.no_of_hubs = e.target.value;
                                                                setFeatures(prev)
                                                            }}value={features.no_of_hubs} />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="from-label">No of Teams</label>
                                                    <input type="number" className="form-control" placeholder="1" onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.no_of_teams = e.target.value;
                                                                setFeatures(prev)
                                                            }} value={features.no_of_teams} />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="from-label">Historical Analysis(optional) in yrs</label>
                                                    <input type="number" className="form-control" placeholder="1"onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.historical_analysis = e.target.value;
                                                                setFeatures(prev)
                                                            }} value={features.historical_analysis} />

                                                </div>
                                                
                                                <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" checked={features.photo_proof} id={1}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.photo_proof = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={1}>
                                                               Photo Proof of Delivery
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" checked={features.email_support} id={2}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.email_support = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={2}>
                                                              Email Support
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" checked={features.quick_route_organize} id={3}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.quick_route_organize = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={3}>
                                                              Quick Route Organize
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" checked={features.basic_route_optimization} id={4}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.basic_route_optimization = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={4}>
                                                              Basic Route Optimization
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" checked={features.advance_route_optimization} id={5}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.advance_route_optimization = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={5}>
                                                              Advance Route Optimization *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" checked={features.basic_eta_notification} id={6}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.basic_eta_notification = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={6}>
                                                              Basic ETA Notification
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" checked={features.advance_eta_notification} id={7}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.advance_eta_notification = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={7}>
                                                              Advance ETA Notification *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" checked={features.api_access} id={8}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.api_access = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={8}>
                                                             Api Access *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" checked={features.delivery_status} id={9}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.delivery_status = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={9}>
                                                            Delivery Status *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" checked={features.task_completion_customization} id={10}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.task_completion_customization = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={10}>
                                                           Task Completion Customization *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" checked={features.private_label_recipient_tracking_page} id={11}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.private_label_recipient_tracking_page = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={11}>
                                                          Private Label Recipient Tracking Page *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" checked={features.onboarding_and_support} id={12}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.onboarding_and_support = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={12}>
                                                         Onboarding And Support *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" checked={features.sla} id={13}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.sla = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={13}>
                                                        SLA *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" checked={features.enterprise_sso} id={14}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.enterprise_sso = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={14}>
                                                            Enterprise SSO *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" checked={features.multibrand_region_support} id={15}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.multibrand_region_support = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={15}>
                                                            MultiBrand Region Support *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" checked={features.bulk_sms} id={16}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.bulk_sms = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={16}>
                                                            Bulk SMS
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" checked={features.unlimited_users} id={17}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.unlimited_users = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={17}>
                                                            Unlimited Users *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3" >
                                                        <div className="ml-3">
                                                            <input className="form-check-input" type="checkbox" checked={features.twilio_messaging} id={18}
                                                               onChange={e => {
                                                                const prev = JSON.parse(JSON.stringify(features))
                                                                // const prev = features
                                                              
                                                                prev.twilio_messaging = e.target.checked;
                                                                setFeatures(prev)
                                                            }}
                                                                
                                                            />
                                                            <label className="form-check-label mb-0 text-nowrap" htmlFor={18}>
                                                            SMS Messaging
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="card-footer border-0 text-end py-3 ">
                                    <button className="btn btn-primary" onClick={handleSubmit}>Save</button>
                                </div>
                                            </div>

                                        </div>
                                        </Card.Body>
                                        </Card>
					</Col>
                    <Col lg={12}>
						<Card>
							<Card.Header>
								<Card.Title>Messaging Charges</Card.Title>
							</Card.Header>
							<Card.Body>
                            <table className="table table-bordered table-responsive-lg table-striped table-condensed flip-content">
                                                <thead>
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>Phone</th>
                                                        <th>SMS</th>
                                                        <th>Charge</th>
                                                        <th>Invoice Sent</th>
                                                        <th>Is Paid</th>
                                                        <th>Created At</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {smsCharges && smsCharges.map((charge,i)=>(
                                                        <tr key={charge._id}>
                                                            <td>{i+1}</td>
                                                            <td>{charge.phone}</td>
                                                            <td>{charge.sms} </td>
                                                            <td>{charge.amount} </td>
                                                            <td>{charge.invoice_send?'yes':'no'} </td>
                                                            <td>{charge.paid?'yes':'no'} </td>
                                                            <td>{new Date(charge.created_at).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"}) }</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
							</Card.Body>
						</Card>
					</Col>
                    </div>
                </div>
            </div>

        </>
    );
};

export default ContentAdd;
