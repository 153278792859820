export const MenuList = [
    //Dashboard
    {
        title: 'Dashboard',	
        classsChange: 'mm-collapse',		
        iconStyle: <i className="fas fa-home"></i>,
        to: '/dashboard',		
    },    
    {
        title : "Plans",
        classsChange: 'mm-collapse',
        update:"New",
        iconStyle: <i className="fas fa-file" />,
        to:'/plans',
    },
    {
        title : "Pages",
        classsChange: 'mm-collapse',
        update:"New",
        iconStyle: <i className="fas fa-file" />,
        to:'/pages',
    },
    {
        title : "Email Templates",
        classsChange: 'mm-collapse',
        update:"New",
        iconStyle: <i className="fas fa-file" />,
        to:'/emailtemplates',
    },
    {
        title : "Faqs",
        classsChange: 'mm-collapse',
        update:"New",
        iconStyle: <i className="fas fa-question" />,
        to:'/faqs',
        // content:[
        //     {
        //         title:'Content',
        //         to:'/content'
        //     },
        //     {
        //         title:'Menu',
        //         to:'/menu'
        //     },
        //     {
        //         title:'Email Template',
        //         to:'/email-template'
        //     },
        //     {
        //         title:'Blog',
        //         to:'/blog'
        //     },
        // ],
    },
    {
        title : "General Settings",
        classsChange: 'mm-collapse',
        update:"New",
        iconStyle: <i className="fas fa-cog" />,
        to:'/general-setting',
        // content:[
        //     {
        //         title:'Content',
        //         to:'/content'
        //     },
        //     {
        //         title:'Menu',
        //         to:'/menu'
        //     },
        //     {
        //         title:'Email Template',
        //         to:'/email-template'
        //     },
        //     {
        //         title:'Blog',
        //         to:'/blog'
        //     },
        // ],
    },
    {
        title : "Feature Prices",
        classsChange: 'mm-collapse',
        update:"New",
        iconStyle: <i className="fas fa-chart-line" />,
        to:'/feature-prices',
        // content:[
        //     {
        //         title:'Content',
        //         to:'/content'
        //     },
        //     {
        //         title:'Menu',
        //         to:'/menu'
        //     },
        //     {
        //         title:'Email Template',
        //         to:'/email-template'
        //     },
        //     {
        //         title:'Blog',
        //         to:'/blog'
        //     },
        // ],
    },
    {
        title : "Twilio Charge",
        classsChange: 'mm-collapse',
        update:"New",
        iconStyle: <i className="fas fa-phone" />,
        to:'/twiliocharges',
        // content:[
        //     {
        //         title:'Content',
        //         to:'/content'
        //     },
        //     {
        //         title:'Menu',
        //         to:'/menu'
        //     },
        //     {
        //         title:'Email Template',
        //         to:'/email-template'
        //     },
        //     {
        //         title:'Blog',
        //         to:'/blog'
        //     },
        // ],
    },
    {
        title : "User Subscriptions",
        classsChange: 'mm-collapse',
        update:"New",
        iconStyle: <i className="fas fa-user" />,
        to:'/subscribers',
        // content:[
        //     {
        //         title:'Content',
        //         to:'/content'
        //     },
        //     {
        //         title:'Menu',
        //         to:'/menu'
        //     },
        //     {
        //         title:'Email Template',
        //         to:'/email-template'
        //     },
        //     {
        //         title:'Blog',
        //         to:'/blog'
        //     },
        // ],
    },
    // {
    //     title : "Pages",
    //     classsChange: 'mm-collapse',
    //     update:"New",
    //     iconStyle: <i className="fas fa-file" />,
    //     to:'/content',
        // content:[
        //     {
        //         title:'Content',
        //         to:'/content'
        //     },
        //     {
        //         title:'Menu',
        //         to:'/menu'
        //     },
        //     {
        //         title:'Email Template',
        //         to:'/email-template'
        //     },
        //     {
        //         title:'Blog',
        //         to:'/blog'
        //     },
        // ],
    // },
   
    {
        title : "Contact Us",
        classsChange: 'mm-collapse',
        update:"New",
        iconStyle: <i className="fas fa-phone" />,
        to:'/contact-us',
        // content:[
        //     {
        //         title:'Content',
        //         to:'/content'
        //     },
        //     {
        //         title:'Menu',
        //         to:'/menu'
        //     },
        //     {
        //         title:'Email Template',
        //         to:'/email-template'
        //     },
        //     {
        //         title:'Blog',
        //         to:'/blog'
        //     },
        // ],
    },
    {
        title : "Trial Settings",
        classsChange: 'mm-collapse',
        update:"New",
        iconStyle: <i className="fas fa-money-bill" />,
        to:'/trial-setting',
        // content:[
        //     {
        //         title:'Content',
        //         to:'/content'
        //     },
        //     {
        //         title:'Menu',
        //         to:'/menu'
        //     },
        //     {
        //         title:'Email Template',
        //         to:'/email-template'
        //     },
        //     {
        //         title:'Blog',
        //         to:'/blog'
        //     },
        // ],
    },
   
   
    //Apps
    // {
    //     title: 'Apps',	
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="fas fa-info-circle"></i>,
    //     content: [
    //         {
    //             title: 'Profile',
    //             to: '/app-profile'
    //         },
    //         {
    //             title: 'Edit Profile',
    //             to: 'edit-profile'
    //         },
    //         {
    //             title: 'Post Details',
    //             to: '/post-details'
    //         },            
    //         {
    //             title: 'Email',
    //             //to: './',
    //             hasMenu : true,
    //             content: [
    //                 {
    //                     title: 'Compose',
    //                     to: '/email-compose',
    //                 },
    //                 {
    //                     title: 'Index',
    //                     to: '/email-inbox',
    //                 },
    //                 {
    //                     title: 'Read',
    //                     to: '/email-read',
    //                 }
    //             ],
    //         },
    //         {
    //             title:'Calendar',
    //             to: '/app-calender'
    //         },
    //         {
    //             title: 'Shop',
    //             //to: './',
    //             hasMenu : true,
    //             content: [
    //                 {
    //                     title: 'Product Grid',
    //                     to: '/ecom-product-grid',
    //                 },
    //                 {
    //                     title: 'Product List',
    //                     to: '/ecom-product-list',
    //                 },
    //                 {
    //                     title: 'Product Details',
    //                     to: '/ecom-product-detail',
    //                 },
    //                 {
    //                     title: 'Order',
    //                     to: '/ecom-product-order',
    //                 },
    //                 {
    //                     title: 'Checkout',
    //                     to: '/ecom-checkout',
    //                 },
    //                 {
    //                     title: 'Invoice',
    //                     to: '/ecom-invoice',
    //                 },
    //                 {
    //                     title: 'Customers',
    //                     to: '/ecom-customers',
    //                 },
    //             ],
    //         },
    //     ],
    // },
    //Charts
    // {
    //     title: 'Charts',	
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="fas fa-chart-line" />,
    //     content: [
            
    //         {
    //             title: 'RechartJs',
    //             to: '/chart-rechart',					
    //         },
    //         {
    //             title: 'Chartjs',
    //             to: '/chart-chartjs',					
    //         },
    //         {
    //             title: 'Sparkline',
    //             to: '/chart-sparkline',					
    //         },
    //         {
    //             title: 'Apexchart',
    //             to: '/chart-apexchart',					
    //         },
    //     ]
    // },
    
    //Boosttrap
    // {
    //     title: 'Bootstrap',	
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="fab fa-bootstrap" />,	
    //     content: [
    //         {
    //             title: 'Accordion',
    //             to: '/ui-accordion',					
    //         },
    //         {
    //             title: 'Alert',
    //             to: '/ui-alert',					
    //         },
    //         {
    //             title: 'Badge',
    //             to: '/ui-badge',					
    //         },
    //         {
    //             title: 'Button',
    //             to: 'ui-button',					
    //         },
    //         {
    //             title: 'Modal',
    //             to: '/ui-modal',					
    //         },
    //         {
    //             title: 'Button Group',
    //             to: '/ui-button-group',					
    //         },
    //         {
    //             title: 'List Group',
    //             to: '/ui-list-group',					
    //         },
    //         {
    //             title: 'Cards',
    //             to: '/ui-card',					
    //         },
    //         {
    //             title: 'Carousel',
    //             to: '/ui-carousel',					
    //         },
    //         {
    //             title: 'Dropdown',
    //             to: '/ui-dropdown',					
    //         },
    //         {
    //             title: 'Popover',
    //             to: '/ui-popover',					
    //         },
    //         {
    //             title: 'Progressbar',
    //             to: '/ui-progressbar',					
    //         },
    //         {
    //             title: 'Tab',
    //             to: '/ui-tab',					
    //         },
    //         {
    //             title: 'Typography',
    //             to: '/ui-typography',					
    //         },
    //         {
    //             title: 'Pagination',
    //             to: '/ui-pagination',					
    //         },
    //         {
    //             title: 'Grid',
    //             to: '/ui-grid',					
    //         },
    //     ]
    // },
    //plugins
    // {
    //     title:'Plugins',
    //     classsChange: 'mm-collapse',
    //     iconStyle : <i className="fas fa-heart" />,
    //     content : [
    //         {
    //             title:'Select 2',
    //             to: '/uc-select2',
    //         },
    //         // {
    //         //     title:'Noui Slider',
    //         //     to: 'uc-noui-slider',
    //         // },
    //         {
    //             title:'Sweet Alert',
    //             to: '/uc-sweetalert',
    //         },
    //         {
    //             title:'Toastr',
    //             to: '/uc-toastr',
    //         },
    //         {
    //             title:'Jqv Map',
    //             to: '/map-jqvmap',
    //         },
    //         {
    //             title:'Light Gallery',
    //             to: '/uc-lightgallery',
    //         },
    //     ]
    // },
    // {
    //     title: 'Redux',	
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-087-stop"></i>,
    //     content: [
    //         {
    //             title: 'Todo',
    //             to: '/todo'
    //         },
    //     ]
    // },
    //Widget
    // {   
    //     title:'Widget',
    //     //classsChange: 'mm-collapse',
    //     iconStyle: <i className="fas fa-user-check" />,
    //     to: 'widget-basic',
    // },
    //Forms
    // {
    //     title:'Forms',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="fas fa-file-alt" />,
    //     content : [
    //         {
    //             title:'Form Elements',
    //             to: '/form-element',
    //         },
    //         {
    //             title:'Wizard',
    //             to: '/form-wizard',
    //         },
    //         {
    //             title:'CkEditor',
    //             to: '/form-ckeditor',
    //         },
    //         {
    //             title:'Pickers',
    //             to: '/form-pickers',
    //         },
    //         {
    //             title:'Form Validate',
    //             to: '/form-validation',
    //         },

    //     ]
    // },
    //Table
    // {
    //     title:'Table',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="fas fa-table" />,
    //     content : [
    //         {
    //             title:'Table Filtering',
    //             to: '/table-filtering',
    //         },
    //         {
    //             title:'Table Sorting',
    //             to: '/table-sorting',
    //         },
    //         {
    //             title:'Bootstrap',
    //             to: '/table-bootstrap-basic',
    //         },
           

    //     ]
    // },
    //Pages
    // {
    //     title:'Pages',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="fas fa-clone" />,
    //     content : [
    //         {
    //             title:'Error',
    //             hasMenu : true,
    //             content : [
    //                 {
    //                     title: 'Error 400',
    //                     to : '/page-error-400',
    //                 },
    //                 {
    //                     title: 'Error 403',
    //                     to : '/page-error-403',
    //                 },
    //                 {
    //                     title: 'Error 404',
    //                     to : '/page-error-404',
    //                 },
    //                 {
    //                     title: 'Error 500',
    //                     to : '/page-error-500',
    //                 },
    //                 {
    //                     title: 'Error 503',
    //                     to : '/page-error-503',
    //                 },
    //             ],
    //         },
    //         {
    //             title:'Lock Screen',
    //             to: '/page-lock-screen',
    //         },

    //     ]
    // },
    
]