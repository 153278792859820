import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import {
	Dropdown, Nav, Tab, Row, Col, Card, Table,
	Badge
} from 'react-bootstrap';
import VisitorActivity from "./../../pages/WidgetBasic/VisitorActivity";
import ChartPie from "./../../components/charts/Chartjs/pie";



//Images
import chart1 from './../../../images/chart.png';
import pic3 from './../../../images/profile/small/pic3.jpg';
import pic4 from './../../../images/profile/small/pic4.jpg';
import pic5 from './../../../images/profile/small/pic5.jpg';
import pic6 from './../../../images/profile/small/pic6.jpg';
//import pic7 from './../../../images/profile/small/pic7.jpg';
import pic8 from './../../../images/profile/small/pic8.jpg';
import wind from './../../../images/big-wind.png';
import hunt from './../../../images/circle-hunt.png';

//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import ProjectStatisticsTab from './Dashboard/ProjectStatisticsTab';
import ProfileSlider from './Dashboard/ProfileSlider';
const CompletionApexChart = loadable(() =>
	pMinDelay(import("./Dashboard/CompletionApexChart"), 1000)
);
const ClientsColumnChart = loadable(() =>
	pMinDelay(import("./Dashboard/ClientsColumnChart"), 1000)
);
const NewCustomersApex = loadable(() =>
	pMinDelay(import("./Dashboard/NewCustomersApex"), 1000)
);
const NewCustomersApex2 = loadable(() =>
	pMinDelay(import("./Dashboard/NewCustomersApex2"), 1000)
);
const ProfileRedialApex = loadable(() =>
	pMinDelay(import("./Dashboard/ProfileRedialApex"), 1000)
);
const EmailChartApex = loadable(() =>
	pMinDelay(import("./Dashboard/EmailChartApex"), 1000)
);


const MessagesBlog = [
	{ images: pic8, title: 'Maren Rosser', para: 'Hei, dont forget to clear server cache!', datetime: '25min ago' },
	{ images: pic5, title: 'Kaiya Bergson', para: 'I remember that project due is tomorrow.', datetime: 'Yesterday, 8:24 AM' },
	{ images: pic6, title: 'Ruben Press', para: 'Ok sir. I will fix it as soon as possible', datetime: 'December 12th, 2020 10:24 AM' },
	{ images: pic3, title: 'Cristofer Torff', para: 'Maybe we should schedule that meeting', datetime: 'December 12th, 2020 10:24 AM' },
	{ images: pic4, title: 'Ann Rosser', para: 'I dont’t know where that files saved dude.', datetime: 'Yesterday, 8:24 AM' },
];

const Home = () => {
	const { changeBackground } = useContext(ThemeContext);
	const svg1 = (
		<svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<rect x="0" y="0" width="24" height="24"></rect>
				<circle fill="#000000" cx="5" cy="12" r="2"></circle>
				<circle fill="#000000" cx="12" cy="12" r="2"></circle>
				<circle fill="#000000" cx="19" cy="12" r="2"></circle>
			</g>
		</svg>
	);
	const [transaction_count, setTransactionCount] = useState(0);
	const [subscriber_count, setSubscriberCount] = useState(0);
	const [user_count, setUserCount] = useState(0);
	const [plan_count, setPlanCount] = useState(0);
	useEffect(() => {
	
		const fetchAllData = async () => {
			const response = await fetch('/api/dashboard/getAllData')
			const json = await response.json();
			if(response.ok){
				setTransactionCount(json.transaction_count);
				setSubscriberCount(json.subscriber_count);
				setUserCount(json.user_count);
				setPlanCount(json.plan_count);
			   
			}
			
		}
		fetchAllData()
		changeBackground({ value: "light", label: "Light" });
	}, []);

	return (
		<>
			<div className="row">
				<div className="col-xl-12">
					<div className="row">
						<div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
							<div className="widget-stat card bg-success">
								<div className="card-body p-4">
									<div className="media">
										<span className="me-3">
											<i className="flaticon-381-user-7"></i>
										</span>
										<div className="media-body text-white text-end">
											<p className="mb-1">Total Users</p>
											<h3 className="text-white">{user_count}</h3>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
							<div className="widget-stat card bg-info">
								<div className="card-body p-4">
									<div className="media">
										<span className="me-3">
											<i className="flaticon-381-calendar-1"></i>
										</span>
										<div className="media-body text-white text-end">
											<p className="mb-1">Total Subscription</p>
											<h3 className="text-white">{subscriber_count}</h3>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
							<div className="widget-stat card bg-warning">
								<div className="card-body p-4">
									<div className="media">
										<span className="me-3">
											<i className="flaticon-381-calendar-1"></i>
										</span>
										<div className="media-body text-white text-end">
											<p className="mb-1">Total Plans</p>
											<h3 className="text-white">{plan_count}</h3>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
							<div className="widget-stat card bg-danger">
								<div className="card-body p-4">
									<div className="media">
										<span className="me-3">
											<i className="flaticon-381-calendar-1"></i>
										</span>
										<div className="media-body text-white text-end">
											<p className="mb-1">Total Transactions</p>
											<h3 className="text-white">{transaction_count}</h3>
										</div>
									</div>
								</div>
							</div>
						</div>


					</div>
				
					<div className="row">
					<div className="col-xl-6 col-xxl-6 col-lg-12 col-sm-12">
						<div id="user-activity" className="card">
							<Tab.Container defaultActiveKey="day">
								<div className="card-header border-0 pb-0 d-sm-flex d-block">
									<h4 className="card-title">Bar Charts</h4>
									<div className="card-action mb-sm-0 my-2">
										<Nav className="nav nav-tabs" role="tablist">
											<Nav.Item className="nav-item">
												<Nav.Link
													className="nav-link "
													data-toggle="tab"
													to="/widget-basic"
													role="tab"
													eventKey="day"
												>
													Day
												</Nav.Link>
											</Nav.Item>
											<Nav.Item className="nav-item">
												<Nav.Link
													className="nav-link"
													data-toggle="tab"
													to="/widget-basic"
													role="tab"
													eventKey="month"
												>
													Month
												</Nav.Link>
											</Nav.Item>
											<Nav.Item className="nav-item">
												<Nav.Link
													className="nav-link"
													data-toggle="tab"
													to="/widget-basic"
													role="tab"
													eventKey="year"
												>
													Year
												</Nav.Link>
											</Nav.Item>
										</Nav>
									</div>
								</div>
								<div className="card-body">
									<Tab.Content className="tab-content" id="myTabContent">
										<Tab.Pane eventKey="day" id="user" role="tabpanel">
											<VisitorActivity dataActive={0} />
										</Tab.Pane>
										<Tab.Pane eventKey="month" id="user" role="tabpanel">
											<VisitorActivity dataActive={1} />
										</Tab.Pane>
										<Tab.Pane eventKey="year" id="user" role="tabpanel">
											<VisitorActivity dataActive={2} />
										</Tab.Pane>
									</Tab.Content>
								</div>
							</Tab.Container>
						</div>
					</div>
					<Col xl={6} lg={6}>
						<Card>
							<Card.Header>
								<h4 className="card-title">Pie</h4>
							</Card.Header>
							<Card.Body>
								<ChartPie />
							</Card.Body>
						</Card>
					</Col>
					</div>
					<Col lg={12}>
						<Card>
							<Card.Header>
								<Card.Title>Recent Subscribed User</Card.Title>
							</Card.Header>
							<Card.Body>
								<Table responsive>
									<thead>
										<tr>
											<th className="width80">
												<strong>#</strong>
											</th>
											<th>
												<strong>Email</strong>
											</th>
											<th>
												<strong>NAME</strong>
											</th>
											<th>
												<strong>DATE</strong>
											</th>
											<th>
												<strong>STATUS</strong>
											</th>
											<th>
												<strong>PRICE</strong>
											</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<strong>01</strong>
											</td>
											<td>Ram@localfoodz.co</td>
											<td>Ram Sharma</td>
											<td>01 August 2020</td>
											<td>
												<Badge variant="success light">Successful</Badge>
											</td>
											<td>$21.56</td>
											<td>
												<Dropdown>
													<Dropdown.Toggle
														variant="success"
														className="light sharp i-false"
													>
														{svg1}
													</Dropdown.Toggle>
													<Dropdown.Menu>
														<Dropdown.Item>Edit</Dropdown.Item>
														<Dropdown.Item>Delete</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											</td>
										</tr>
										<tr>
											<td>
												<strong>02</strong>
											</td>
											<td>Ram@localfoodz.co</td>
											<td>Ram Sharma</td>
											<td>01 August 2020</td>
											<td>
												<Badge variant="danger light">Canceled</Badge>
											</td>
											<td>$21.56</td>
											<td>
												<Dropdown>
													<Dropdown.Toggle
														variant="danger"
														className="light sharp i-false"
													>
														{svg1}
													</Dropdown.Toggle>
													<Dropdown.Menu>
														<Dropdown.Item>Edit</Dropdown.Item>
														<Dropdown.Item>Delete</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											</td>
										</tr>
										<tr>
											<td>
												<strong>03</strong>
											</td>
											<td>Ram@localfoodz.co</td>
											<td>Ram Sharma</td>
											<td>01 August 2020</td>
											<td>
												<Badge variant="warning light">Pending</Badge>
											</td>
											<td>$21.56</td>
											<td>
												<Dropdown>
													<Dropdown.Toggle
														variant="warning"
														className="light sharp i-false"
													>
														{svg1}
													</Dropdown.Toggle>
													<Dropdown.Menu>
														<Dropdown.Item>Edit</Dropdown.Item>
														<Dropdown.Item>Delete</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											</td>
										</tr>
									</tbody>
								</Table>
							</Card.Body>
						</Card>
					</Col>

				</div>
			</div>

		</>
	)
}
export default Home;