import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import swal from "sweetalert";	


import { useAuthContext } from '../../../hooks/useAuthContext';


const ContentAdd = () => {
    const [id,setId] = useState(0)
    const [company_name,setCompanyName] = useState('')
    const [company_email,setCompanyEmail] = useState('')
    const [company_logo,setCompanyLogo] = useState(null)
    const [company_logo_asset,setCompanyLogoAssets] = useState(null)
    const [company_number,setCompanyNumber] = useState('')
    const [company_address,setCompanyAddress] = useState('')
    const [company_fav_icon,setCompanyFavIcon] = useState('')
    const [meta_title,setMetaTitle] = useState('')
    const [meta_description,setMetaDescription] = useState('')
    const [meta_keyword,setMetaKeyword] = useState('')
    const [google_console_setting,setGoogleConsoleSetting] = useState('')
    const [chat_js_setting,setChatJsSetting] = useState('')
    const [facebook_link,setFacebookLink] = useState('')
    const [twitter_link,setTwitterLink] = useState('')
    const [instagram_link,setInstagramLink] = useState('')

    const {user} = useAuthContext()

    useEffect(() => {
        const fetchSetting = async () => {
            const response = await fetch('/api/setting/')
            const json = await response.json();
            if(response.ok){
               
                setId(json._id)
                setCompanyName(json.company_name)
                setCompanyLogo(json.company_logo)
                setCompanyEmail(json.company_email)
                setCompanyAddress(json.company_address)
                setCompanyNumber(json.company_number)
                setChatJsSetting(json.chat_js_setting)
                setFacebookLink(json.facebook_link)
                setGoogleConsoleSetting(json.google_console_setting)
                setChatJsSetting(json.chat_js_setting)
                setTwitterLink(json.twitter_link)
                setInstagramLink(json.instagram_link)
                setMetaDescription(json.meta_description)
                setMetaKeyword(json.meta_keyword)
                setMetaTitle(json.meta_title)
                if(json.company_logo)
                setCompanyLogoAssets(`http://localhost:7081/images/`+json.company_logo)
            }
            
        }
        fetchSetting()
    }, []);
    const onInputChange = (e) => {
        console.log(e.target.files[0]);
        setCompanyLogo(e.target.files[0]);
        setCompanyLogoAssets(URL.createObjectURL(e.target.files[0]))
      };
      
      const submitImage = async(e) =>{
    
        const formData = new FormData();
        formData.append("image", company_logo);
    
        const response =  fetch('/api/setting/uploadCompanyLogo',{
            method:'POST',
            body:formData,
            headers:{
                'Authorization':`Bearer ${user.token}`
            }
        });
        const json = await response.json();
        if(!response.ok){
            swal(json.error,'','error');
        }
      }
    const handleSubmit = async(e) =>{
        e.preventDefault()
        console.log(id);
   


        const setting = {id,company_name,company_email,company_number,company_fav_icon,meta_title,meta_description,meta_keyword,google_console_setting,chat_js_setting,facebook_link,twitter_link,instagram_link,company_address
        }

        const response = await fetch('/api/setting/',{
            method:'POST',
            body:JSON.stringify(setting),
            headers:{
                'Content-Type':'application/json',
                'Authorization':`Bearer ${user.token}`
            }
        })

        const json = await response.json();
        if(!response.ok){
            swal(json.error,'','error');
        }

        if(response.ok){
      // update the auth context
      if(company_logo != null)
      submitImage();
            swal("Setting Updated Successfully",'','success');
        }
    }
    return (
        <>

            <div className="row">
                <div className="col-xl-12">
                    <div className="row page-titles mt-3">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={"#"}>Home</Link></li>
                            <li className="breadcrumb-item active">General Setting</li>
                        </ol>
                    </div>


                    <div className="row">
                    <div class="card">
                            <div className="card-header">
                                <h4 className="card-title">General Setting</h4>
                            </div>
                            <div class="card-body">
                        <div className="col-xl-8">
                            <form>
                            <input type="hidden" value={id} />
                                <div className="mb-3">
                                    <label className="from-label">Company Name</label>
                                    <input type="text" className="form-control" placeholder="Company Name" onChange={(e)=>setCompanyName(e.target.value)}
                                    value={company_name} />
                                </div>
                                <div className="mb-3">
                                    <label className="from-label">Company Address</label>
                                    <input type="text" className="form-control" placeholder="Company Address" onChange={(e)=>setCompanyAddress(e.target.value)}
                                    value={company_address} />
                                </div>
                                <div className="mb-3">
                                    <label className="from-label">Company Email</label>
                                    <input type="email" className="form-control" placeholder="Company Email" onChange={(e)=>setCompanyEmail(e.target.value)}
                                    value={company_email} />
                                </div>
                                <div className="mb-3">
                                    <label className="from-label">Company Number</label>
                                    <input type="text" className="form-control" placeholder="Company Number" onChange={(e)=>setCompanyNumber(e.target.value)}
                                    value={company_number} />
                                </div>
                                <div className="change-btn d-flex align-items-center flex-wrap mb-3">
                                    <input type="file" accept="image/*" onChange={onInputChange} id="imageUpload"
                                   
                                    />
                                    <label for="imageUpload" className="dlab-upload bg-light ms-0">Select Company Logo</label>
                                    {company_logo_asset  && ( <img
                src={company_logo_asset}
                alt="company logo"
                height={100}
                width={100}
              />)}
                                </div>
                                <div className="mb-3">
                                    <label className="from-label">Meta Title</label>
                                    <input type="text" className="form-control" placeholder="Meta title" onChange={(e)=>setMetaTitle(e.target.value)}
                                    value={meta_title} />
                                </div>
                                <div className="mb-3">
                                    <label className="from-label">Meta Keyword</label>
                                    <input type="text" className="form-control" placeholder="Meta Keyword" onChange={(e)=>setMetaKeyword(e.target.value)}
                                    value={meta_keyword} />
                                </div>
                                <div className="mb-3">
                                    <label className="from-label">Meta Description</label>
                                    <textarea type="text" className="form-control" rows="5" placeholder="Meta Description" onChange={(e)=>setMetaDescription(e.target.value)}
                                    value={meta_description} ></textarea>
                                </div>
                                <div className="mb-3">
                                    <label className="from-label">Google Console Setting</label>
                                    <textarea type="text" className="form-control" rows="5" placeholder="Google Console Setting" onChange={(e)=>setGoogleConsoleSetting(e.target.value)}
                                    value={google_console_setting} ></textarea>
                                </div>
                                <div className="mb-3">
                                    <label className="from-label">Chat Js Setting</label>
                                    <textarea type="text" className="form-control" rows="5" placeholder="Chat Js Setting" onChange={(e)=>setChatJsSetting(e.target.value)}
                                    value={chat_js_setting} ></textarea>
                                </div>
                                <div className="mb-3">
                                    <label className="from-label">facebook link</label>
                                    <input type="text" className="form-control" placeholder="fb link" onChange={(e)=>setFacebookLink(e.target.value)}
                                    value={facebook_link} />
                                </div>
                                <div className="mb-3">
                                    <label className="from-label">Instagram Link</label>
                                    <input type="text" className="form-control" placeholder="Instagram link" onChange={(e)=>setInstagramLink(e.target.value)}
                                    value={instagram_link} />
                                </div>
                                <div className="mb-3">
                                    <label className="from-label">Twitter Link</label>
                                    <input type="text" className="form-control" placeholder="Twitter Link" onChange={(e)=>setTwitterLink(e.target.value)}
                                    value={twitter_link} />
                                </div>
                            </form>


                            <div className="card-footer border-0 text-end py-3 ">
                            <button className="btn btn-primary" onClick={handleSubmit}>Save</button>
                            </div>

                        </div>
                        </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    );
};

export default ContentAdd;
