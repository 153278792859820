import { SubscriberContext } from "../context/SubscriberContext";
import { useContext } from "react";

export const useSubscribersContext = () => {
    const context = useContext(SubscriberContext)

    if(!context){
        throw Error('useSubscriberContext must be used inside and SubscriberContextProvider')
    }

    return context
}