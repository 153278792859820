import React,{useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { connect, useDispatch } from 'react-redux';
import {useSignup} from "../../hooks/useSignup"
import {
    loadingToggleAction,
    signupAction,
} from '../../store/actions/AuthActions';
// image
import logo from "../../images/logo-full.png";

function Register(props) {
    const {signup, error, isLoading} = useSignup()
    const [email, setEmail] = useState('');
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    let errorsObj = { email: '', password: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('customer');

    const dispatch = useDispatch();
    const Navigate =  useNavigate();

    const handleSubmit = async (e) => {
      e.preventDefault()

      await signup(email, password,role,first_name,last_name)
      setEmail('')
      setPassword('')
      setFirstName('')
      setLastName('')
    }
  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/login">
                        <img src={logo} alt="" />
                      </Link>
                    </div>
                      <h4 className="text-center mb-4 ">Sign up your account</h4>
                      {props.errorMessage && (
                        <div className=''>
                          {props.errorMessage}
                        </div>
                      )}
                      {props.successMessage && (
                        <div className=''>
                          {props.successMessage}
                        </div>
                      )}
                    <form onSubmit={handleSubmit}>
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>First Name</strong>
                        </label>
                        <input
                          value={first_name}
                          onChange={(e) => setFirstName(e.target.value)}
                          className="form-control"
                          placeholder="First Name"
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Last Name</strong>
                        </label>
                        <input
                          value={last_name}
                          onChange={(e) => setLastName(e.target.value)}
                          className="form-control"
                          placeholder="Last Name"
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Email</strong>
                        </label>
                        <input
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="form-control"
                          placeholder="email"
                        />
                      </div>
					                {errors.email && <div>{errors.email}</div>}
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Password</strong>
                        </label>
                        <input
                          value={password}
                          onChange={(e) =>
                            setPassword(e.target.value)
                          }
                          type="password"
                            className="form-control"
                            placeholder="password"
                          //defaultValue="Password"
                        />
                      </div>
					            {errors.password && <div>{errors.password}</div>}
                      <div className="form-group mb-3 d-none">
                        <label className="mb-1 ">
                          <strong>Role</strong>
                        </label>
                        <input
                          value={role}
                          onChange={(e) => setRole(e.target.value)}
                          className="form-control"
                          placeholder="role"
                        />
                      </div>
                      <div className="text-center mt-4">
                      <button disabled={isLoading}  className="btn btn-primary btn-block">Sign up</button>
      {error && <div className="error">{error}</div>}
                      </div>
                    </form>
                    <div className="new-account mt-3">
                      <p className="">
                        Already have an account?{" "}
                        <Link className="text-primary" to="/login">
                          Sign in
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};

export default connect(mapStateToProps)(Register);

