import { createContext, useReducer } from "react";

export const SubscriberContext = createContext();

export const subscribersReducer = (state, action) => {
    switch(action.type){
        case 'SET_SUBSCRIBERS':
            return{
                subscribers:action.payload
            }
        case 'CREATE_SUBSCRIBER':
            return {
                subscribers:[action.payload, ...state.subscribers]
            }  
        case 'DELETE_SUBSCRIBER':
            return {
                plans:state.plans.filter((p)=>
                p._id !== action.payload._id
                )
            }  
        default:
            return state
    }
}

export const SubscribersContextProvider = ({children}) =>{
  const [state, dispatch] = useReducer(subscribersReducer,{
    subscribers:null
  })

    return(
        <SubscriberContext.Provider value={{...state, dispatch}}>
            {children}
        </SubscriberContext.Provider>
    )
}