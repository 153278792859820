import React, { useReducer, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../../hooks/useAuthContext'

import Select from 'react-select';
import { Collapse, Button, Modal } from 'react-bootstrap';

import NoImage from '../../../images/no-image.jpg';
import CkEditorBlog from '../Forms/CkEditor/CkEditorBlog';
import bg1 from "../../../images/big/img1.jpg";
import profile from "../../../images/profile/profile.png";
import swal from "sweetalert";




const options = [
    //{ value: '1', label: 'Select Status' },
    { value: '2', label: 'admin@gmail.com' },
    { value: '3', label: 'India' },
    { value: '4', label: 'Information' },
    { value: '5', label: 'New Menu' },
    { value: '6', label: 'Page Menu' }
]

const options2 = [
    { value: '1', label: 'Published' },
    { value: '2', label: 'Draft' },
    { value: '3', label: 'Trash' },
    { value: '4', label: 'Private' },
    { value: '5', label: 'Pending' }
]

const options3 = [
    { value: '1', label: 'Privacy Policy' },
    { value: '2', label: 'Contact Us' },
    { value: '3', label: 'Important Information' },
    { value: '4', label: 'Free shipping' },
    { value: '5', label: 'Daily Gifts' },
    { value: '6', label: '477 505 8877' },
    { value: '7', label: 'About Us' },
    { value: '8', label: 'Dummy Co' }
]

const options4 = [
    { value: '1', label: 'Active' },
    { value: '2', label: 'InActive' },
]
const options5 = [
    { value: '1', label: 'Monthly' },
    { value: '2', label: 'Yearly' },
]
const options6 = [
    { value: '1', label: '1 year historical analysis' },
    { value: '2', label: '7 year historical analysis' },
]



const initialState = true;
const reducer = (state, action) => {
    switch (action.type) {
        case 'collpase0':
            return { ...state, collpase0: !state.collpase0 }
        case 'collpase1':
            return { ...state, collpase1: !state.collpase1 }
        case 'collpase2':
            return { ...state, collpase2: !state.collpase2 }
        case 'collpase3':
            return { ...state, collpase3: !state.collpase3 }
        case 'collpase4':
            return { ...state, collpase4: !state.collpase4 }
        case 'collpase5':
            return { ...state, collpase5: !state.collpase5 }
        case 'collpase6':
            return { ...state, collpase6: !state.collpase6 }
        case 'collpase7':
            return { ...state, collpase7: !state.collpase7 }
        case 'collpase8':
            return { ...state, collpase8: !state.collpase8 }
        case 'collpase9':
            return { ...state, collpase9: !state.collpase9 }
        case 'section1':
            return { ...state, section1: !state.section1 }
        case 'section2':
            return { ...state, section2: !state.section2 }
        case 'section3':
            return { ...state, section3: !state.section3 }
        case 'section4':
            return { ...state, section4: !state.section4 }
        case 'section5':
            return { ...state, section5: !state.section5 }
        case 'section6':
            return { ...state, section6: !state.section6 }
        case 'section7':
            return { ...state, section7: !state.section7 }
        case 'section8':
            return { ...state, section8: !state.section8 }
        case 'section9':
            return { ...state, section9: !state.section9 }
        default:
            return state
    }
}

const screenOption = [
    { id: "1", title: 'Custom Fields', series: '14' },
    { id: "2", title: 'Discussion', series: '15' },
    { id: "3", title: 'Slug', series: '16' },
    { id: "4", title: 'Author', series: '17' },
    { id: "5", title: 'Seo', series: '19' },
    { id: "6", title: 'Published', series: '13' },
    { id: "7", title: 'Page Attributes', series: '11' },
    { id: "8", title: 'Page Type', series: '18' },
    { id: "9", title: 'Featured Image', series: '12' },

];
const screenOption1 = [
    { id: "1", title: 'Photo Proof of Delivery', series: '14' },
    { id: "2", title: 'Email Support', series: '15' },
    { id: "3", title: 'ETA', series: '16' },
    { id: "10", title: 'Task Completion Customization', series: '16' },
    { id: "11", title: 'Delivery Status', series: '16' },
    { id: "4", title: 'Import/Export Deliveries', series: '17' },
    { id: "5", title: 'Basic Route Optimization', series: '19' },
    { id: "6", title: 'Advance Route Optimization', series: '13' },
    { id: "7", title: 'Custom Messaging', series: '11' },
    { id: "8", title: 'Live Driver Tracking', series: '18' },
    { id: "9", title: 'Text Messaging Charge Support in arears', series: '12' },

];

const ContentAdd = () => {
    const [state ] = useReducer(reducer, initialState);
    const { user, dispatch } = useAuthContext()

    // function addClass() {
    //     setTimeout(()=>{
    //     let showData = document.querySelector('.heading');            
    //         return showData.classList.toggle("show");           
    //     },100)
    // }  
    console.log('user from componetn',user)
    const [plans, setPlans] = useState('');
    const [planName, setPlanName] = useState('');
    const [updatePlan, setUpdatePlan] = useState('');
    const [updatePlanInterval, setUpdatePlanInterval] = useState('month');
    const [planPrice, setUpdatePlanPrice] = useState('');
    const [upgradeModal, setUpgradeModal] = useState(false);
    useEffect(() => {
        const fetchPlans = async () => {
            const response = await fetch('/api/plans')
            const json = await response.json();
            if (response.ok) {
                setPlans(json)
                console.log('json', json);
            }

        }
        fetchPlans()
    }, []);

    const [file, setFile] = React.useState(null)
    const fileHandler = (e) => {
        setFile(e.target.files[0]);
    }

    const updateSubscription = async (plan, plan_interval, price, plan_name) => {
        setUpgradeModal(true);
        setUpdatePlan(plan);
        setUpdatePlanInterval(plan_interval);
        setUpdatePlanPrice(price);
        setPlanName(plan_name);
    }
    const cancelSubscription = async () => {
        try{
        const response = await fetch('/api/stripe/cancelSubscription', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
        });
        const json = await response.json();
        console.log(json.error);
    
        if (json?.error) {

            swal(json.error, '', 'error')
        } else {
          

            swal('subscription upgraded', '', 'success')
        }
    } catch (error) {

        swal(error, '', 'error')
        console.log(error);
    }
    }
    const handleSubmit = async () => {
        try {



            // create a payment method


            const response = await fetch('/api/stripe/updateSubscription', {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                },
                body: JSON.stringify({ plan_id: updatePlan, plan_interval: updatePlanInterval })
            });
            const json = await response.json();
        
            if (response?.error) {
                setUpgradeModal(false);

                swal(response.error, '', 'error')
            } else {
                setUpgradeModal(false);
                localStorage.setItem('user', JSON.stringify(json))
                dispatch({ type: 'UPDATE_USER', payload: json })
                console.log('new_json',JSON.stringify(json));

                swal('subscription upgraded', '', 'success')
            }
        } catch (error) {
            setUpgradeModal(false);

            swal(error, '', 'error')
            console.log(error);
        }
    }
    return (
        <>

            <div className="row">
                <div className="col-xl-12">
                    <div className="row page-titles mt-3">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={"#"}>Home</Link></li>
                            <li className="breadcrumb-item active">Upgrade Subscription</li>
                        </ol>
                    </div>


                    <div className="row">
                    <button className="btn btn-primary btn-sm" onClick={() => cancelSubscription()}>
                                                    <i className="fa fa-bell-o"></i>Cancel Subscription
                                                </button> 
                      
                        {plans && plans.map((item) => {
                            return (
                                <div className="col-xl-3 col-lg-12 col-sm-12">
                                    <div className="card overflow-hidden ">
                                        <div
                                            className="text-center p-3 overlay-box "
                                            style={{ backgroundImage: `url(${bg1})` }}
                                        >
                                            <div className="profile-photo">

                                            </div>
                                            <h3 className="mt-3 mb-1 text-white">{item.title}</h3>
                                            <p className="text-white mb-0">${item.monthly_plan_price}/month ${item.yearly_plan_price}/Year
                                            </p>
                                        </div>
                                        <p className="list-unstyled pt-3 border-top subscription-upgrade-p" dangerouslySetInnerHTML={{ __html: item.description }} ></p>


                                        <div className="card-footer border-0 mt-0">
                                            {user.current_plan != item._id ?
                                                <button className="btn btn-primary btn-lg btn-block" onClick={() => updateSubscription(item._id, 'month', item.monthly_plan_price, item.title)}>
                                                    <i className="fa fa-bell-o"></i>Upgrade Monthly Plan
                                                </button> : ''}
                                            {user.current_plan != item._id ?
                                                <button className="btn btn-primary btn-lg btn-block mt-2" onClick={() => updateSubscription(item._id, 'year', item.yearly_plan_price, item.title)}>
                                                    <i className="fa fa-bell-o"></i>Upgrade Yearly Plan
                                                </button> : ''}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>
            <Modal className="fade" show={upgradeModal}>
                <Modal.Header>
                    <Modal.Title>
                        Upgrade 					<h5 className='text-danger'> It will cost: ${planPrice}</h5>

                    </Modal.Title>
                    <Button
                        onClick={() => setUpgradeModal(false)}
                        variant=""
                        className="btn-close"
                    >

                    </Button>
                </Modal.Header>
                <Modal.Body>
                    Are You Sure You Want to Upgrade to {planName} ?
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => setUpgradeModal(false)}
                        variant="danger light"
                    >
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => handleSubmit()}>Submit</Button>
                </Modal.Footer>
            </Modal>

        </>
    );
};

export default ContentAdd;
