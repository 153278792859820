import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Collapse from 'react-bootstrap/Collapse';
import { usePlansContext } from '../../../hooks/usePlansContext';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import axios from 'axios';
import swal from "sweetalert";	






// Load languages you need




const tableData = [
    { number: "1", title: "Trial" },
    { number: "2", title: "Basic" },
    { number: "3", title: "Intermediate" },
    { number: "4", title: "Advance" },
];

const ChargeList = ({ }) => {
    const [modalCentered, setModalCentered] = useState(false);
    const [ucharge, setCharge] = useState('');

    const [open, setOpen] = useState(true);
    const [open2, setOpen2] = useState(true);

    const [data, setData] = useState(
        document.querySelectorAll("#content_wrapper tbody tr")
    );
    const sort = 8;
    const activePag = useRef(0);
    const [test, settest] = useState(0);

    // Active data
    const chageData = (frist, sec) => {
        for (var i = 0; i < data.length; ++i) {
            if (i >= frist && i < sec) {
                data[i].classList.remove("d-none");
            } else {
                data[i].classList.add("d-none");
            }
        }
    };
    const generateCSV = () => {
        // Define the CSV content
        const csvData = [
          ["countryCode", "smsOutbound", "smsInbound", "updatedAt"],
          ["US", "0.079", "0.078", "2024-08-27T09:01:15.321Z"],
          ["CA", "0.085", "0.080", "2024-08-27T09:01:15.321Z"],
          ["GB", "0.090", "0.085", "2024-08-27T09:01:15.321Z"],
          ["AU", "0.095", "0.090", "2024-08-27T09:01:15.321Z"],
          ["IN", "0.070", "0.065", "2024-08-27T09:01:15.321Z"]
        ];
      
        // Convert CSV data to a string
        const csvContent = csvData.map(row => row.join(",")).join("\n");
      
        // Create a Blob object with the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      
        // Create a link element and set the href attribute to the Blob URL
        const link = document.createElement("a");
        if (link.download !== undefined) {
          // Set the download attribute with the desired filename
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", "sample.csv");
          link.style.visibility = 'hidden';
      
          // Append the link to the body and trigger a click
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      };

    const { user } = useAuthContext()

    const [charges, setCharges] = useState(null)
    const fetchCharges = async () => {
        const response = await fetch('/api/twilio', {
            headers: { 'Authorization': `Bearer ${user.token}` }
        })
        const json = await response.json();
        if (response.ok) {
            setCharges(json);

        }

    }


    // use effect
    useEffect(() => {
       

        fetchCharges()
    }, [user]);




    // Active pagginarion
    activePag.current === 0 && chageData(0, sort);
    // paggination
    let paggination = Array(Math.ceil(data.length / sort))
        .fill()
        .map((_, i) => i + 1);

    // Active paggination & chage data
    const onClick = (i) => {
        activePag.current = i;
        chageData(activePag.current * sort, (activePag.current + 1) * sort);
        settest(i);
    };

    const [deleteItem, setDeleteItem] = useState(tableData);
    const handleDelete = async () => {

        const response = await fetch('/api/twilio/' + ucharge._id, {
            headers: { 'Authorization': `Bearer ${user.token}` },
            method: 'DELETE'
        })
        const json = await response.json()

        if (response.ok) {
            setModalCentered(false)
            fetchCharges();


        }
    }
    const openDeleteModal = async (charge) => {
        setModalCentered(true)
        setCharge(charge)

    }

    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post('/api/twilio/upload-csv', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

                swal("File uploaded successfully",'','success');
                setFile(null);
                document.getElementById('formFile').value = '';
                fetchCharges();

           
        } catch (error) {
            console.error('Error uploading file:', error);
            swal("Failed to upload file",'','error');
        }
    };




    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="mb-3">
                        <Link to={"/add-twilio-charge"} className="btn btn-primary ">Add Twilio Charge </Link>

                        <form onSubmit={handleSubmit}>
                            <div class="mb-3 col-3">
                                <label for="formFile" class="form-label">Import CSV file for bulk upload</label>
                                <button className="btn btn-warning mb-2" type="button" onClick={generateCSV}>Download Sample CSV</button>

                                <input class="form-control" type="file" id="formFile" onChange={handleFileChange} />
                            </div>

                            <button type="submit" className="btn btn-primary">Upload CSV</button>
                        </form>
                    </div>
                    <div className="filter cm-content-box box-primary mt-5">
                        <div className={`content-title ${open2 ? "" : "collbord"}`}>
                            <div className="cpa">
                                Twilio Charge List
                            </div>
                            <div className="tools">
                                <Link to={"#"} className="expand SlideToolHeader"
                                    onClick={() => setOpen2(!open2)}
                                >
                                    <i className="fas fa-angle-up"></i>
                                </Link>
                            </div>
                        </div>
                        <Collapse in={open2}>
                            <div className="cm-content-body form excerpt">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <div id="content_wrapper" className="dataTables_wrapper no-footer">
                                            <table className="table table-bordered table-responsive-lg table-striped table-condensed flip-content">
                                                <thead>
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>Country Code</th>
                                                        <th>SMS IN Bound Charge</th>
                                                        <th>SMS Out Bound Charge</th>
                                                        <th className="text-end">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {charges && charges.map((charge, i) => (
                                                        <tr key={charge._id}>
                                                            <td>{i + 1}</td>
                                                            <td>{charge.countryCode}</td>
                                                            <td>{charge.smsInbound}</td>
                                                            <td>{charge.smsOutbound}</td>
                                                            <td className='text-end'>
                                                                <Link to={"/edit-twilio-charge/" + charge._id} className="btn btn-warning btn-sm content-icon me-1">
                                                                    <i className="fa fa-edit"></i>
                                                                </Link>

                                                                <Link to={"#"} className="btn btn-danger btn-sm content-icon ms-1"
                                                                    onClick={() => openDeleteModal(charge)}
                                                                >
                                                                    <i className="fa fa-times"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                        <Modal className="fade" show={modalCentered}>
                            <Modal.Header>
                                <Modal.Title>Delete Charge</Modal.Title>
                                <Button
                                    onClick={() => setModalCentered(false)}
                                    variant=""
                                    className="btn-close"
                                >

                                </Button>
                            </Modal.Header>
                            <Modal.Body>
                                <p>
                                    Are you Sure you want to delete?
                                </p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    onClick={() => setModalCentered(false)}
                                    variant="danger light"
                                >
                                    Close
                                </Button>
                                <Button variant="primary" onClick={() => handleDelete()}>Yes</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ChargeList;