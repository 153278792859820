import React, {useState, useRef, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Select from 'react-select';
import Collapse from 'react-bootstrap/Collapse';
import { usePlansContext } from '../../../hooks/usePlansContext';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import { useSubscribersContext } from '../../../hooks/useSubscribersContext';




const tableData = [
    {number:"1", title:"Trial"},
    {number:"2", title:"Basic"},
    {number:"3", title:"Intermediate"},
    {number:"4", title:"Advance"},
];

const PlanList = ({}) =>{
    const [modalCentered, setModalCentered] = useState(false);
    const [subscriptions, setSubscriptions] = useState('');

    const [open, setOpen] = useState(true);
    const [open2, setOpen2] = useState(true);

    const [data, setData] = useState(
		document.querySelectorAll("#content_wrapper tbody tr")
	);
	const sort = 8;
	const activePag = useRef(0);
	const [test, settest] = useState(0);

	// Active data
	const chageData = (frist, sec) => {
		for (var i = 0; i < data.length; ++i) {
			if (i >= frist && i < sec) {
				data[i].classList.remove("d-none");
			} else {
				data[i].classList.add("d-none");
			}
		}
	};

    const {subscribers, dispatch} = useSubscribersContext()
    const {user} = useAuthContext()

    // const [plans,setPlans] = useState(null)


   // use effect
   useEffect(() => {

    const fetchSubscriptions = async () => {
        const response = await fetch('/api/stripe/getUserSubscription',{
            headers:{'Authorization':`Bearer ${user.token}`}
        })
        const json = await response.json();
        if(response.ok){
            setSubscriptions(json)
        }
        
    }
    fetchSubscriptions()
}, [dispatch,user]);

   

  
   // Active pagginarion
   activePag.current === 0 && chageData(0, sort);
   // paggination
   let paggination = Array(Math.ceil(data.length / sort))
      .fill()
      .map((_, i) => i + 1);

   // Active paggination & chage data
	const onClick = (i) => {
		activePag.current = i;
		chageData(activePag.current * sort, (activePag.current + 1) * sort);
		settest(i);
	};
  
  
    return(
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="mb-3">
                        {/* <Link to={"/add-plan"} className="btn btn-primary">Add Plan</Link> */}
                    </div>
                    <div className="filter cm-content-box box-primary mt-5">
                        <div className={`content-title ${open2 ? "" : "collbord"  }`}>
                            <div className="cpa">
                                <i className="fas fa-file-word me-2"></i>User Subscription List
                            </div>
                            <div className="tools">
                                <Link to={"#"} className="expand SlideToolHeader"
                                     onClick={() => setOpen2(!open2)}
                                >
                                    <i className="fas fa-angle-up"></i>
                                </Link>
                            </div>
                        </div>
                        <Collapse in={open2}>
                            <div className="cm-content-body form excerpt">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <div id="content_wrapper" className="dataTables_wrapper no-footer">
                                            <table className="table table-bordered table-responsive-lg table-striped table-condensed flip-content">
                                                <thead>
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>Price</th>
                                                        <th>Plan</th>
                                                        <th>Start</th>
                                                        <th>End</th>
                                                        <th>Remarks</th>
                                                        <th>Modified</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {subscriptions && subscriptions.map((subscriber)=>(
                                                        <tr key={subscriber._id}>
                                                            <td>{subscriber._id}</td>
                                                            <td>${subscriber.price/100}</td>
                                                            <td>{subscriber.plans?subscriber.plans.title:''} </td>
                                                            <td>{new Date(subscriber.period_start).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"}) }</td>
                                                            <td>{new Date(subscriber.period_end).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"}) }</td>
                                                            <td>{subscriber.remarks}</td>
                                                            <td>{new Date(subscriber.createdAt).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"}) }</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                                                             
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Collapse> 
                        
                       
                    </div>
                </div>
            </div>
        </>
    )
}
export default PlanList;